import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { ApiService } from './api.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Role } from '../_models/role';

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject: BehaviorSubject<any>;
  private allUsersSubject: BehaviorSubject<User[]>;
  public currentUser: Observable<any>;
  public allUsers: Observable<any>;

  userQuery$: any;
  usersQuery$: any;

  currentUserObservable$: any;

  @Output() userForm: FormGroup;

  constructor(private api: ApiService, private fb: FormBuilder) {
    // fixme: improve observables/subjects and their use in the app
    this.getCurrentUser();
    this.getAllUsers();
    this.userQuery$.subscribe(() => {});
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') ?? '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.allUsersSubject = new BehaviorSubject<User[]>([]);
    this.allUsers = this.allUsersSubject.asObservable();

    this.usersQuery$.subscribe(() => {});

    // this.userForm = this.fb.group({})

    this.userForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      role_id: new FormControl('', Validators.required),
      enabled: new FormControl(true),
      scope: new FormControl(''),
      password: new FormControl('', Validators.required),
    });
  }

  getAllUsers() {
    this.usersQuery$ = this.api.getAPI<any>('users').pipe(
      map((response: any) => {
        let allCurrentUsers = response.body;
        console.log('allCurrentUsers', allCurrentUsers);
        this.allUsersSubject.next(allCurrentUsers);
        return allCurrentUsers;
      })
    );
  }

  public get allUsersValue(): User[] {
    return this.allUsersSubject.value;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getCurrentUser() {
    localStorage.removeItem('currentUser');
    // this.userQuery$ = this.api.getAPI<any>('users/me/').pipe(
    //   map((response: any) => {
    //     let currentActiveUser = response.body;
    //     console.log('currentActiveUser', currentActiveUser);
    //     localStorage.setItem('currentUser', JSON.stringify(currentActiveUser));
    //     this.currentUserSubject.next(currentActiveUser);
    //     return currentActiveUser;
    //   })
    // );

    this.userQuery$ = this.api.getAPI<any>('salesrep/me/').pipe(
      map((response: any) => {
        let currentActiveUser = response.body;
        console.log('currentActiveUser', currentActiveUser);
        localStorage.setItem('currentUser', JSON.stringify(currentActiveUser));
        this.currentUserSubject.next(currentActiveUser);
        return currentActiveUser;
      })
    );
  }

  postNewUser(user: any) {
    this.api.postAPI('users/register', user).subscribe((response) => {
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 202
      ) {
        console.log('user created');
      } else {
        console.log('user not created');
      }
    });
  }

  getRoleValue(role_id?: number) {
    if (role_id) {
      return Role[role_id];
    } else if (this.currentUserValue) {
      let role_identification = this.currentUserValue.role_id ?? -1;
      return Role[role_identification];
    }
    return '';
  }

  clearCurrentUser() {
    this.currentUserSubject.next(null);
  }

  getSalesRepId(): any {
    return this.currentUserValue?.sage_sales_rep_id;
  }
}
