import { Injectable, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Invoice } from 'src/app/_models/invoice';
import { ApiService } from 'src/app/_services/api.service';
import { Customer } from '../../_models/customer';
import { CustomerCategory } from '../../_models/customer-category';
import { Product } from '../../_models/product';
import { CustomerService } from './customer.service';
import { OfflineQueueService } from '../offline-queue.service';
import { PricelistService } from './pricelist.service';
import { ProductPricelistService } from './product-pricelist.service';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  // pollInterval = 5000;
  // allInvoices: any[];

  @Output() invoiceObservable$: Subject<any> = new Subject<any>();
  @Output() invoiceProductLinesObservable$: Subject<any> = new Subject<any>();
  @Output() invoiceForm: FormGroup;
  @Output() notesForm: FormGroup;

  isLoadingInvoices: boolean;
  invoices: any;

  invoiceQuery$: any;
  invoiceProductLinesQuery$: any;
  isLoadingInvoiceProductLines: boolean | undefined;
  invoiceProductLines: any;

  constructor(
    private api: ApiService,
    private offlineQueueService: OfflineQueueService
  ) {
    // this.initialiseNewInvoice();

    this.isLoadingInvoices = false;

    this.getInvoices();
    this.getInvoiceLines();
    this.offlineQueueService.clearQueue();

    this.notesForm = new FormGroup({
      // note fields:
      order_no: new FormControl(''),
      truck_reg: new FormControl(''),
      driver_name: new FormControl(''),
      mileage: new FormControl(0),
      fleet_ref: new FormControl(''),
    });

    this.invoiceForm = new FormGroup({
      customer_id: new FormControl('customer_id', Validators.required),
      status: new FormControl('', Validators.required),
      sage_invoice_id: new FormControl('', Validators.required),
      date_issued: new FormControl(
        new Date().toISOString(),
        Validators.required
      ),
      date_due: new FormControl(new Date().toISOString(), Validators.required),
      amount_total: new FormControl(0, Validators.required),
      sage_sales_rep_id: new FormControl(''),
      notes: new FormControl(''),
      amount_due: new FormControl(0, Validators.required),
      invoice_reference: new FormControl(''),
      sage_invoice_reference: new FormControl(''),
    });

    // this.invoiceForm.valueChanges.subscribe(() => {
    //   console.log('invoice form value changed');
    // });
  }

  getInvoices() {
    this.isLoadingInvoices = true;
    this.invoiceQuery$ = this.api
      .getAPI<Customer>('invoice', { limit: 2500 }, 1000)
      .subscribe((response) => {
        this.invoices = response.body;
        this.invoiceObservable$.next(this.invoices);
        this.isLoadingInvoices = false;
      });
  }

  getInvoiceLines() {
    this.isLoadingInvoiceProductLines = false;
    this.invoiceProductLinesQuery$ = this.api
      .getAPI<any>('invoiceproductline', { limit: 5000 }, 10000)
      .subscribe((response) => {
        this.invoiceProductLines = response.body;
        this.invoiceProductLinesObservable$.next(this.invoiceProductLines);
        this.isLoadingInvoiceProductLines = false;
      });
  }

  getInvoiceLinesByInvoiceId(invoice_id: number) {
    this.isLoadingInvoiceProductLines = false;
    this.invoiceProductLinesQuery$ = this.api
      .getAPI<any>(
        `invoiceproductline/invoice/${invoice_id}`,
        {
          limit: 5000,
        },
        10000
      )
      .subscribe((response) => {
        this.invoiceProductLines = response.body;
        this.invoiceProductLinesObservable$.next(this.invoiceProductLines);
        this.isLoadingInvoiceProductLines = false;
      });
  }

  // todo: test if this flow works
  postInvoice(invoice: any) {
    this.offlineQueueService
      .tryPostAPI('invoice/add', invoice)
      .subscribe((response) => {
        console.log('invoice created');
        let invoiceLines = this.convertActiveInvoiceItemstoInvoiceLines(
          invoice.items,
          response.body.id,
          invoice.notes
        );
        this.postInvoiceLines(invoiceLines);
        return response;
      });
  }

  // todo: test if this flow works
  postInvoiceLines(invoiceLines: any[]) {
    invoiceLines.forEach((line: any) => {
      this.offlineQueueService
        .tryPostAPI('invoiceproductline/add', line)
        .subscribe((response) => {
          console.log('invoice line created');
        });
    });
  }

  convertActiveInvoiceItemstoInvoiceLines(
    invoiceItems: Product[],
    invoice_id: number,
    invoiceNotes: string
  ) {
    // fixme: dependent on successful invoice creation, does not work when offline
    let new_invoice_lines: any[] = [];
    let new_invoice_product_line = {};

    invoiceItems.forEach((item: any) => {
      let comment = '';

      if (
        item.description.toLowerCase().includes('m diesel-') ||
        item.description.toLowerCase().includes('parking')
      ) {
        comment = invoiceNotes;
        // console.log('this is the item', item);
      }
      new_invoice_product_line = {
        quantity: item.product_quantity,
        // todo: add comments/notes to invoice line
        comments: comment,
        invoice_id: invoice_id,
        product_id: item.id,
        unit_price_inclusive: item.price_inclusive,
        unit_price_exclusive: item.price_exclusive,
      };

      // console.log('new_invoice_product_line', new_invoice_product_line);

      new_invoice_lines.push(new_invoice_product_line);
    });
    return new_invoice_lines;
  }

  submitActiveInvoice() {
    // todo:
  }
}
