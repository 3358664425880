import { Component, OnInit, Output } from '@angular/core';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';
import { ReceiptPrintingService } from 'src/app/_services/receipt-printing.service';

@Component({
  selector: 'app-receipt-settings',
  templateUrl: './receipt-settings.component.html',
  styleUrls: ['./receipt-settings.component.scss'],
})
export class ReceiptSettingsComponent implements OnInit {
  updateTimeout($event: any) {
    this.printingSettings.receiptPrinterSettings.timeout = $event.target.value;
  }
  constructor(
    public printingSettings: PrinterSettingsService,
    public receiptPrintingService: ReceiptPrintingService
  ) {}

  ngOnInit(): void {
    this.printingSettings.loadSettings();
  }
}
