import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { CustomerService } from '../../../_services/entities/customer.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
  constructor(
    public customerService: CustomerService,
    public staticDialogService: StaticDialogService,
    // public invoiceService: InvoiceService
    public activeSaleService: ActiveSaleService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  clearCustomer() {
    this.customerService.activeCustomer = null;
    this.staticDialogService.displayInvoiceDialog = false;
    this.activeSaleService.initialiseNewInvoice();
    this.router.navigate(['/']);
  }
}
