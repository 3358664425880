import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';

@Component({
  selector: 'app-transaction-complete',
  templateUrl: './transaction-complete.component.html',
  styleUrls: ['./transaction-complete.component.scss'],
})
export class TransactionCompleteComponent implements OnInit {
  constructor(
    public staticDialogService: StaticDialogService,
    public invoiceService: InvoiceService,
    public activeSaleService: ActiveSaleService
  ) {}

  ngOnInit(): void {}

  submitNotes() {
    this.staticDialogService.closeTransactionCompleteDialog();
    this.staticDialogService.openInvoiceDialog();
  }

  convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }

  cancelTransaction() {
    this.staticDialogService.closeTransactionCompleteDialog();
    this.activeSaleService.initialiseNewInvoice();
  }
}
