import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Customer } from '../../_models/customer';
import { Product } from '../../_models/product';
import { ApiService } from '../api.service';
import { CustomerService } from './customer.service';
import { InvoiceService } from './invoice.service';
import { PricelistService } from './pricelist.service';
import { ProductPricelistService } from './product-pricelist.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  isLoadingProducts: boolean;
  @Output() products: any;
  @Output() productsObservable$: Subject<any> = new Subject<any>();
  productPricelistQuery$: any;
  productPricelist: any;
  pricelistQuery$: any;
  pricelists: any;
  customers: any;

  constructor(
    public api: ApiService,
    private productPricelistService: ProductPricelistService,
    private pricelistService: PricelistService,
    private customerService: CustomerService
  ) {
    this.isLoadingProducts = false;

    this.productPricelistQuery$ =
      this.productPricelistService.productPricelistObservable$.subscribe(
        (productPricelist) => {
          this.productPricelist = productPricelist;
        }
      );

    this.pricelistQuery$ = this.pricelistService.pricelistObservable$.subscribe(
      (pricelist) => {
        this.pricelists = pricelist;
      }
    );

    this.customerService.customersObservable$.subscribe((customers: any) => {
      this.customers = customers;
    });
  }

  getProducts() {
    this.isLoadingProducts = true;
    this.api.getAPI<Product>('product').subscribe((response) => {
      this.products = response.body;
      this.productsObservable$.next(this.products);
      this.isLoadingProducts = false;
    });
  }
  getDefaultPriceList() {
    let defaultPriceListItem = null;
    this.pricelists.forEach((pricelistItem: any) => {
      if (pricelistItem.is_default) {
        defaultPriceListItem = pricelistItem;
      }
    });
    return defaultPriceListItem;
  }

  getDefaultProductPriceList(pricelistId: any) {
    let defaultProductPriceListItem = null;
    this.productPricelist.forEach((productPricelistItem: any) => {
      if (productPricelistItem.pricelist_id === pricelistId) {
        defaultProductPriceListItem = productPricelistItem;
      }
    });
    return defaultProductPriceListItem;
  }

  getDefaultProductPrice(productId: number) {
    let defaultProductItem = null;
    this.products.forEach((productItem: any) => {
      if (productItem.id === productId) {
        defaultProductItem = productItem;
      }
    });
    return defaultProductItem;
  }
  getProductPricelistInfo(productId: any, customerInfo: Customer) {
    /*

    pricelist = additionalpricelist
    productpricelist = additionalitemprice

    what are the steps and what do we want to achieve?

    1. start with product id
    2. get productpricelist object(s)/item(s) that matches the product id because there will be more than one
    3. get pricelist object(s)/item(s) that matches the pricelist_id of the productpricelist object(s)/item(s) because there might be more than one
    4. get customer and match the pricelist object and the productpricelist object that matches the customer

    */
    // 1. start with product id --- Received as argument/parameter for this function

    // 2. get productPricelist object(s) that matches the productId
    let productPricelistArray = this.productPricelist.filter(
      (productPricelist: any) => {
        return productPricelist.product_id === productId;
      }
    );

    console.log('productPricelistArray', productPricelistArray);

    // 3. get pricelist object(s) that matches the pricelist_id of the productpricelist object(s)

    let pricelistArray: any[] = [];

    productPricelistArray.forEach((productPricelistElement: any) => {
      pricelistArray.push(
        this.pricelists.filter((pricelistItem: any) => {
          return pricelistItem.id === productPricelistElement.pricelist_id;
        })[0]
      );
    });
    console.log('pricelistArray', pricelistArray);

    // 4. get customer and match the pricelist object and the productpricelist object that matches the customer

    console.log('customerInfo', customerInfo);

    for (let i = 0; i < pricelistArray.length; i++) {
      if (
        +customerInfo.sage_additional_price_list_id ===
          +pricelistArray[i].sage_additional_price_list_id &&
        +productPricelistArray[i].pricelist_id === +pricelistArray[i].id
      ) {
        console.log('match', pricelistArray[i], productPricelistArray[i]);
        return productPricelistArray[i];
      }
    }
  }
}
