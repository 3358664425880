<app-customer-details></app-customer-details>
<br />

<!-- todo: invoice view after payment has been received -->

<div class="shadow-2 surface-card border-round p-3 h-full">
  <div class="flex align-items-center">
    <form [formGroup]="this.invoiceService.invoiceForm" (ngSubmit)="onSubmit()">
      <div class="grid formgrid p-fluid">
        <!-- todo: get the invoice number from the local till -->
        <!-- <div class="field mb-4 col-12 md:col-6">
          <label for="invoice_id" class="font-medium text-900"
            >Invoice ID</label
          >
          <input
            id="invoice_id"
            type="text"
            pInputText
            [disabled]="true"
            [value]="activeSaleService.activeInvoice.sage_invoice_id"
          />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="customer_email" class="font-medium text-900"
            >Tax Reference</label
          >
          <input
            id="customer_email"
            type="email"
            pInputText
            [disabled]="true"
            [value]="customerService.activeCustomer?.taxreference"
          />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="date_issued" class="font-medium text-900"
            >Invoice Date</label
          >
          <p-calendar
            id="date_issued"
            [showIcon]="true"
            [formControl]="
              convertToFormControl(
                this.invoiceService.invoiceForm.get('date_issued')
              )
            "
            dateFormat="dd-mm-yy"
            [disabled]="true"
          ></p-calendar>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="date_due" class="font-medium text-900">Due Date</label>
          <p-calendar
            id="date_due"
            [showIcon]="true"
            [formControl]="
              convertToFormControl(
                this.invoiceService.invoiceForm.get('date_due')
              )
            "
            dateFormat="dd-mm-yy"
            [disabled]="true"
          ></p-calendar>
        </div> -->
        <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
        <!-- item list -->
        <ng-container
          *ngFor="let item of activeSaleService.activeInvoice.items"
        >
          <!-- description -->
          <div class="field mb-4 col-12 md:col-5">
            <label for="item" class="font-medium text-900">Item</label>
            <div class="flex align-content-center">
              <span class="text-md text-900 font-medium"
                >{{ item.description }}&nbsp;
              </span>
              <span class="text-md text-900 font-normal">
                | {{ item.product_code | uppercase }}</span
              >
            </div>
          </div>
          <div class="field mb-4 col-6 md:col-2">
            <label for="item" class="font-medium text-900">Quantity</label>
            <!-- <input
              id="item"
              type="text"
              pInputText
              [value]="item.product_quantity"
            /> -->
            <div class="flex align-content-end">
              <span class="text-lg text-900 font-normal">{{
                item.product_quantity
              }}</span>
            </div>
          </div>
          <div class="field mb-4 col-6 md:col-2">
            <label for="price" class="font-medium text-900">Price</label>
            <div class="flex align-items-end">
              <!-- todo: this must be improved invoice - item - price -->

              <div></div>
              <span class="text-lg text-900 font-normal">{{
                item.price_inclusive | currency : "R"
              }}</span>
            </div>
          </div>
          <div class="field mb-4 col-6 md:col-3">
            <label for="price" class="font-medium text-900">Subtotal</label>
            <div class="flex align-content-right">
              <!-- todo: this must be improved invoice - item - price -->
              <!-- <input
                pInputNumber
                class="p-inputnumber p-inputnumber-input"
                mode="currency"
                currency="ZAR"
                locale="en-ZA"
                inputId="price_inclusive"
                class="flex-1"
                [value]="item.price_inclusive"
              /> -->
              <div></div>
              <span class="text-lg text-900 font-normal">{{
                item.price_inclusive * item.product_quantity | currency : "R"
              }}</span>
            </div>
          </div>

          <!-- <button
            pButton
            pRipple
            icon="pi pi-plus"
            label="Add Item"
            class="p-button-secondary p-button-outlined w-auto"
          ></button> -->
        </ng-container>
        <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
        <div class="field mb-4 col-12 md:col-12 text-right">
          <span class="text-xl text-600 font-medium mr-2">Total Amount</span>
          <span class="text-xl text-900 font-medium">{{
            this.activeSaleService.activeInvoice.amount_total_inclusive
              | currency : "R"
          }}</span>
        </div>
        <div class="surface-border border-top-1 opacity-100 mb-4 col-12"></div>
        <div class="field mb-4 col-12 md:col-12 text-right">
          <span class="text-xl text-600 font-medium mr-2">Total Due</span>
          <span class="text-xl text-900 font-medium">{{
            this.activeSaleService.activeInvoice.amount_total_due
              | currency : "R"
          }}</span>
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
        <div class="field mb-4 col-12">
          <label for="notes" class="font-medium text-900">Notes</label>
          <textarea
            id="notes"
            pInputTextarea
            [rows]="5"
            [value]="this.invoiceService.invoiceForm.value.notes"
            [disabled]="true"
          ></textarea>
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
        <div class="mb-4 col-12 md:col-12 text-left">
          <p-checkbox
            [binary]="true"
            type="button"
            [label]="activeSaleService.confirmInvoicePaymentText"
            [value]="this.activeSaleService.confirmInvoicePayment"
            (click)="clickConfirmInvoicePayment($event)"
          ></p-checkbox>
        </div>
      </div>
      <!-- formgrid -->
      <!-- <label for="confirmInvoicePayment"
        >I agree that I have received payment</label
      >
      <br />
      <p-toggleButton
        []
        type="button"
        (click)="clickConfirmInvoicePayment($event)"
        offIcon="pi pi-check"
        onIcon="pi pi-times"
        name="confirmInvoicePayment"
      ></p-toggleButton>
      <br />
      <br /> -->
      <button
        pButton
        class="p-button-success"
        type="submit"
        [disabled]="!this.activeSaleService.confirmInvoicePayment"
        [loading]="isLoadingInvoice"
      >
        Generate Invoice
      </button>
    </form>

    <!-- (onClick)="
    receiptPrintingService.printReceipt(this.invoiceService.activeInvoice)
  " -->

    <!-- (onClick)="receiptPrintingService.printCanvas()" -->

    <!-- {{ this.invoiceService.invoiceForm.value | json }}

    {{ this.customerService.activeCustomer | json }} -->
  </div>
</div>
<!-- <canvas id="receiptcanvas" width="250" height="250" #receiptcanvas></canvas>
<img id="receiptlogo" [src]="imgSrc" alt="image" /> -->
