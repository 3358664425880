import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { ɵBrowserAnimationBuilder } from '@angular/platform-browser/animations';
import { CanvasMeasureModel } from '../_models/receipt/canvas-measure.model';

import { CurrencyPipe } from '@angular/common';
import { Invoice } from '../_models/invoice';
import { PrinterSettingsService } from './printer-settings.service';
import { PrintService, UsbDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';
// import * as epson from 'src/js/epos-print-4.1.0.js';

// import { epson } from 'epson-epos-print';

// declare var window: any;
declare var epson: any;

const MAX_CHARACTERS_PER_LINE = 48;

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrintingService {
  activePos: any;

  status: boolean = false;
  usbPrintDriver: UsbDriver;

  constructor(
    private cp: CurrencyPipe,
    public printerSettings: PrinterSettingsService,
    private printService: PrintService
  ) {
    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe((result) => {
      this.status = result;
      if (result) {
        console.log('Connected to printer!!!');
      } else {
        console.log('Not connected to printer.');
      }
    });
  }

  ensureConnected() {
    if (!this.status) {
      this.requestUsb();
    }
  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe((result) => {
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  print(driver?: PrintDriver) {
    this.printService
      .init()
      .setBold(true)
      .writeLine('Hello World, this is a test print!')
      .setBold(false)
      .feed(5)
      .cut('full')
      .flush();
  }

  printCustomerReceipt(
    invoice: Invoice,
    invoiceNotes: any,
    customer: any,
    user: any,
    invoice_reference?: any
  ) {
    //
    // build print data
    //
    const builder = new epson.ePOSBuilder();

    builder.addLayout(builder.LAYOUT_RECEIPT, 580);

    // get current date
    const now = new Date();

    // initialize (ank mode, smoothing)
    builder.addTextLang('en').addTextSmooth(true);

    // this.addLogo(builder);

    // where the logo should be
    builder
      .addTextAlign(builder.ALIGN_CENTER)
      .addTextSize(2, 2)
      .addText('MOJ Petroleum');

    builder.addFeedLine(1);
    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_CENTER);
    builder.addText('Invoice - Customer Copy');

    if (invoice_reference) {
      builder.addFeedLine(1);
      builder
        .addTextSize(1, 1)
        .addTextStyle(false, false, true, builder.COLOR_NONE)
        .addTextAlign(builder.ALIGN_CENTER);
      builder.addText(`${invoice_reference}`);
    }

    builder.addFeedLine(2);
    this.addLineFieldText(builder, 'Company', 'MOJ Petroleum (PTY) LTD');
    this.addLineFieldText(builder, 'Email', 'accounts@petmoj.co.za');
    this.addLineFieldText(
      builder,
      'Cashier',
      user.first_name + ' ' + user.last_name
    );
    this.addLineFieldText(
      builder,
      'Date of Sale',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );
    this.addLineFieldText(
      builder,
      'Date Printed',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );

    // builder.addTextAlign(builder.ALIGN_CENTER);
    // builder.addTextSize(1, 1).addText('');
    // builder.addFeedUnit(16);

    // append customer name
    builder.addFeedLine(1);
    this.addLineFieldText(builder, 'Customer', customer.name);
    // this.addLineFieldText(builder, 'Contact', customer.contact_name);
    this.addLineFieldText(builder, 'Email', customer.email);
    if (customer.telephone) {
      this.addLineFieldText(builder, 'Telephone', customer.telephone);
    }

    if (customer.mobile) {
      this.addLineFieldText(builder, 'Mobile', customer.mobile);
    }

    if (customer.taxreference) {
      this.addLineFieldText(builder, 'Tax Reference', customer.taxreference);
    }

    // todo: get customer details
    builder.addFeedLine(1);

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));

    this.addNotes(builder, invoice);

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));
    builder.addFeedLine(1);

    // invoice line items
    if (invoice.items.length > 0) {
      for (const item of invoice.items) {
        this.addLineItem(builder, item);
        builder.addFeedUnit(16);
      }
    }

    // builder.addText('-'.repeat(MAX_CHARACTERS_PER_LINE));
    builder.addText('');
    builder.addFeedUnit(16);
    this.addTotalLineFieldText(
      builder,
      'Total Excl.',
      this.cp.transform(invoice.amount_total_exclusive, 'R')!
    );
    this.addTotalLineFieldText(
      builder,
      'Total VAT',
      this.cp.transform(invoice.amount_tax, 'R')!
    );
    this.addTotalLineFieldText(
      builder,
      'Total Incl.',
      this.cp.transform(invoice.amount_total_inclusive, 'R')!
    );

    this.addTotalLineFieldText(
      builder,
      'Total Due',
      this.cp.transform(invoice.amount_total_due, 'R')!
    );

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText('# indicates  zero VAT rated items');
    builder.addFeedLine(1);

    // builder.addFeedUnit(32);
    // builder.addTextAlign(builder.ALIGN_CENTER);
    // // builder.addTextSize(6, 4).addText(num);
    // builder.addTextSize(1, 1).addText('');
    // builder.addFeedUnit(16);

    // // append date and time
    // builder.addText();
    builder.addFeedUnit(16);

    builder.addFeedLine(1);

    // append paper cutting
    builder.addCut();

    //
    // send print data
    //
    const epos = this.printCommand();
    epos.send(builder.toString());
    console.log(epos);
  }

  printReceiptUSB(
    invoice: Invoice,
    invoiceNotes: any,
    customer: any,
    user: any,
    intendedCopy: string,
    invoice_reference?: any
  ) {
    const now = new Date();

    this.printService.init();

    this.printService
      .setBold(true)
      .setJustification('center')
      .setSize('large')
      .writeLine('MOJ Petroleum')
      .setSize('normal')
      .setBold(false)
      .feed(2);

    this.printService
      .setBold(true)
      .setJustification('center')
      .writeLine(`Invoice - ${intendedCopy} Copy`)
      .setBold(false)
      .feed(1);

    if (invoice_reference) {
      this.printService
        .setSize('large')
        .setJustification('center')
        .writeLine(`${invoice_reference}`)
        .setSize('normal')
        .feed(2);
    }

    this.addLineFieldUSB('Company', 'MOJ Petroleum (PTY) LTD');
    this.addLineFieldUSB('Email', 'accounts@petmoj.co.za');
    this.addLineFieldUSB('Cashier', user.first_name + ' ' + user.last_name);
    this.addLineFieldUSB(
      'Date of Sale',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );
    this.addLineFieldUSB(
      'Date Printed',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );

    this.printService.feed(1);

    this.addLineFieldUSB('Customer', customer.name);
    // this.addLineFieldText(builder, 'Contact', customer.contact_name);
    this.addLineFieldUSB('Email', customer.email);
    if (customer.telephone) {
      this.addLineFieldUSB('Telephone', customer.telephone);
    }

    if (customer.mobile) {
      this.addLineFieldUSB('Mobile', customer.mobile);
    }

    if (customer.taxreference) {
      this.addLineFieldUSB('Tax Reference', customer.taxreference);
    }

    this.printService.feed(1);

    this.printService.writeLine('='.repeat(MAX_CHARACTERS_PER_LINE));

    this.addNotesUSB(invoiceNotes);

    this.printService.writeLine('='.repeat(MAX_CHARACTERS_PER_LINE));

    if (invoice.items.length > 0) {
      for (const item of invoice.items) {
        this.addLineItemUSB(item);
      }
    }

    this.printService.writeLine('='.repeat(MAX_CHARACTERS_PER_LINE));

    // this.printService.writeLine('');

    this.addTotalLineFieldTextUSB(
      'Total Excl.',
      this.cp.transform(invoice.amount_total_exclusive, 'R')!
    );
    this.addTotalLineFieldTextUSB(
      'Total VAT',
      this.cp.transform(invoice.amount_tax, 'R')!
    );
    this.addTotalLineFieldTextUSB(
      'Total Incl.',
      this.cp.transform(invoice.amount_total_inclusive, 'R')!
    );

    this.addTotalLineFieldTextUSB(
      'Total Due',
      this.cp.transform(invoice.amount_total_due, 'R')!
    );

    this.printService.writeLine('='.repeat(MAX_CHARACTERS_PER_LINE));

    this.printService
      .setJustification('center')
      .setSize('normal')
      .writeLine('# indicates zero VAT rated items')
      .feed(4)
      .cut('full')
      .flush();
  }

  addLineFieldUSB(label: string, value: string, setBold?: boolean) {
    if (setBold === undefined) {
      setBold = true;
    }
    this.printService
      .setJustification('left')
      .setBold(setBold)
      .writeLine(`${label}: ${value}`);
  }

  addLineItemUSB(product: any) {
    this.printService.setJustification('left').setBold(true);

    let productdescription = '';

    // determine tax amount

    if (
      Math.round(
        ((product.price_inclusive - product.price_exclusive) /
          product.price_exclusive) *
          100
      ) > 0
    ) {
      // taxable
      productdescription = '  ';
    } else {
      // non-taxable
      productdescription = '# ';
    }

    productdescription = productdescription + product.description;

    this.printService.writeLine(productdescription);
    let linetext =
      product.product_code +
      ' ' +
      Number(product.product_quantity).toFixed(2) +
      +' ' +
      ' - ' +
      this.cp.transform(product.price_exclusive, 'R')! +
      ' - ' +
      this.cp.transform(product.price_inclusive, 'R')! +
      ' - ' +
      this.cp.transform(
        product.price_inclusive * product.product_quantity,
        'R'
      );

    this.printService.writeLine(linetext);
    this.printService.writeLine('-'.repeat(MAX_CHARACTERS_PER_LINE));
  }

  addTotalLineFieldTextUSB(label: string, value: string) {
    this.printService
      .setJustification('right')
      .setBold(true)
      .writeLine(`${label}: ${value}`);
  }

  addNotesUSB(invoiceNotes: any) {
    this.printService.setBold(false).setJustification('left');

    this.addLineFieldUSB('Order No', invoiceNotes.order_no ?? '', false);

    this.addLineFieldUSB(
      'Registration No',
      invoiceNotes.truck_reg ?? '',
      false
    );

    this.addLineFieldUSB(
      'Fleet Reference No',
      invoiceNotes.fleet_ref?.toString() ?? '',
      false
    );

    this.addLineFieldUSB('Driver Name', invoiceNotes.driver_name ?? '', false);

    this.addLineFieldUSB(
      'Mileage',
      invoiceNotes.mileage?.toString() ?? '',
      false
    );
  }

  printShopReceipt(
    invoice: Invoice,
    customer: any,
    user: any,
    invoice_reference?: any
  ) {
    //
    // build print data
    //
    const builder = new epson.ePOSBuilder();

    builder.addLayout(builder.LAYOUT_RECEIPT, 580);

    // get current date
    const now = new Date();

    // initialize (ank mode, smoothing)
    builder.addTextLang('en').addTextSmooth(true);

    // this.addLogo(builder);

    // where the logo should be
    builder
      .addTextAlign(builder.ALIGN_CENTER)
      .addTextSize(2, 2)
      .addText('MOJ Petroleum');

    builder.addFeedLine(1);
    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_CENTER);
    builder.addText('Invoice - Shop Copy');

    if (invoice_reference) {
      builder.addFeedLine(1);
      builder
        .addTextSize(1, 1)
        .addTextStyle(false, false, true, builder.COLOR_NONE)
        .addTextAlign(builder.ALIGN_CENTER);
      builder.addText(`${invoice_reference}`);
    }

    builder.addFeedLine(2);
    this.addLineFieldText(builder, 'Company', 'MOJ Petroleum (PTY) LTD');
    this.addLineFieldText(builder, 'Email', 'accounts@petmoj.co.za');
    this.addLineFieldText(
      builder,
      'Cashier',
      user.first_name + ' ' + user.last_name
    );
    this.addLineFieldText(
      builder,
      'Date of Sale',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );
    this.addLineFieldText(
      builder,
      'Date Printed',
      now.toDateString() + ' ' + now.toTimeString().slice(0, 8) + ''
    );

    // builder.addTextAlign(builder.ALIGN_CENTER);
    // builder.addTextSize(1, 1).addText('');
    // builder.addFeedUnit(16);

    // append customer name
    builder.addFeedLine(1);
    this.addLineFieldText(builder, 'Customer', customer.name);
    // this.addLineFieldText(builder, 'Contact', customer.contact_name);
    this.addLineFieldText(builder, 'Email', customer.email);
    if (customer.telephone) {
      this.addLineFieldText(builder, 'Telephone', customer.telephone);
    }

    if (customer.mobile) {
      this.addLineFieldText(builder, 'Mobile', customer.mobile);
    }

    if (customer.taxreference) {
      this.addLineFieldText(builder, 'Tax Reference', customer.taxreference);
    }

    // todo: get customer details
    builder.addFeedLine(1);

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));

    this.addNotes(builder, invoice);

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));
    builder.addFeedLine(1);

    // invoice line items
    if (invoice.items.length > 0) {
      for (const item of invoice.items) {
        this.addLineItem(builder, item);
        builder.addFeedUnit(16);
      }
    }

    // builder.addText('-'.repeat(MAX_CHARACTERS_PER_LINE));
    builder.addText('');
    builder.addFeedUnit(16);
    this.addTotalLineFieldText(
      builder,
      'Total Excl.',
      this.cp.transform(invoice.amount_total_exclusive, 'R')!
    );
    this.addTotalLineFieldText(
      builder,
      'Total VAT',
      this.cp.transform(invoice.amount_tax, 'R')!
    );
    this.addTotalLineFieldText(
      builder,
      'Total Incl.',
      this.cp.transform(invoice.amount_total_inclusive, 'R')!
    );

    this.addTotalLineFieldText(
      builder,
      'Total Due',
      this.cp.transform(invoice.amount_total_due, 'R')!
    );

    builder.addText('='.repeat(MAX_CHARACTERS_PER_LINE));

    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addText('# indicates  zero VAT rated items');
    builder.addFeedLine(1);

    // builder.addFeedUnit(32);
    // builder.addTextAlign(builder.ALIGN_CENTER);
    // // builder.addTextSize(6, 4).addText(num);
    // builder.addTextSize(1, 1).addText('');
    // builder.addFeedUnit(16);

    // // append date and time
    // builder.addText();
    builder.addFeedUnit(16);

    builder.addFeedLine(1);

    // append paper cutting
    builder.addCut();

    //
    // send print data
    //
    const epos = this.printCommand();
    epos.send(builder.toString());
    console.log(epos);
  }

  getUrl = (): string => {
    // const { ipAddress, deviceId, timeout } = this.localStore.getCurrentSetting();
    // return 'http://' + ipAddress + '/cgi-bin/epos/service.cgi?devid=' + deviceId + '&timeout=' + timeout;

    this.printerSettings.loadSettings();

    let protocolPrefix = 'http';

    if (this.printerSettings.receiptPrinterSettings.useHTTPS) {
      protocolPrefix = 'https';
    }

    return (
      protocolPrefix +
      '://' +
      this.printerSettings.receiptPrinterSettings.ipAddress +
      '/cgi-bin/epos/service.cgi?devid=' +
      'local_printer' +
      '&timeout=' +
      this.printerSettings.receiptPrinterSettings.timeout
    );

    // return 'http://10.10.10.34/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
  };

  canvasDrawImage = (
    context: CanvasRenderingContext2D,
    measure: CanvasMeasureModel,
    image: string
  ) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const { dx, dy, dh, dw } = measure;
      if (dw && dh) {
        context.drawImage(img, dx, dy, dw, dh);
      } else {
        context.drawImage(img, dx, dy);
      }
    };
  };

  canvasInitialize = (
    image: string,
    measure: CanvasMeasureModel,
    hasClear?: boolean
  ) => {
    const canvas = document.getElementById(
      'receiptcanvas'
    ) as HTMLCanvasElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    if (hasClear) {
      context.clearRect(0, 0, 512, 480);
    }
    this.canvasDrawImage(context, measure, image);
    return { canvas, context };
  };

  printCommand = (isCanvas?: boolean) => {
    // create print object
    const url = this.getUrl();
    const epos = isCanvas
      ? new epson.CanvasPrint(url)
      : new epson.ePOSPrint(url);
    this.activePos = epos;
    // register callback function
    epos.onreceive = this.onReceive.bind(this);

    // register callback function
    epos.onerror = this.onError.bind(this);

    return epos;
  };

  onReceive(res: any) {
    console.log(res);
  }

  onError(err: any) {
    console.log(err);
  }

  addTotalLineFieldText(builder: any, label: string, value: string) {
    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_RIGHT);
    builder.addText(label + ': ');

    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, false, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_RIGHT);
    builder.addText(value);
    builder.addFeedLine(1);
  }

  addLineFieldText(builder: any, label: string, value: string) {
    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_LEFT);
    builder.addText(label + ': ');

    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, false, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_RIGHT);
    builder.addText(value);
    builder.addFeedLine(1);
  }

  addLineItem(builder: any, product: any) {
    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_LEFT);

    let productdescription = '';

    // determine tax amount

    if (
      Math.round(
        ((product.price_inclusive - product.price_exclusive) /
          product.price_exclusive) *
          100
      ) > 0
    ) {
      // taxable
      productdescription = '  ';
    } else {
      // non-taxable
      productdescription = '# ';
    }

    productdescription = productdescription + product.description;

    builder.addText(productdescription);

    builder.addFeedLine(1);

    // let linetext = '';

    let linetext =
      product.product_code +
      ' ' +
      Number(product.product_quantity).toFixed(2) +
      +' ' +
      ' - ' +
      this.cp.transform(product.price_exclusive, 'R')! +
      ' - ' +
      this.cp.transform(product.price_inclusive, 'R')! +
      ' - ' +
      this.cp.transform(
        product.price_inclusive * product.product_quantity,
        'R'
      );

    // console.log('this is the line text ', linetext);
    // console.log('this is the line quantity ', product.product_quantity);

    builder.addText(linetext);
    builder.addFeedLine(1);
    builder.addText('-'.repeat(MAX_CHARACTERS_PER_LINE));
  }

  addNotes(builder: any, invoice: Invoice) {
    // todo: implement notes on receipt

    builder
      .addTextSize(1, 1)
      .addTextStyle(false, false, true, builder.COLOR_NONE)
      .addTextAlign(builder.ALIGN_LEFT);

    this.addLineFieldText(builder, 'Order No', invoice.order_no ?? '');

    this.addLineFieldText(builder, 'Registration No', invoice.truck_reg ?? '');

    this.addLineFieldText(
      builder,
      'Fleet Reference No',
      invoice.fleet_ref?.toString() ?? ''
    );

    this.addLineFieldText(builder, 'Driver Name', invoice.order_no ?? '');

    this.addLineFieldText(
      builder,
      'Mileage',
      invoice.mileage?.toString() ?? ''
    );
  }

  addLogo(builder: any) {
    var canvas = document.getElementById('receiptcanvas') as HTMLCanvasElement;

    // context.clearRect(0, 0, 512, 480);
    var img = new Image();
    img.onload = function () {
      context.drawImage(img, 0, 0, 150, 150);
    };
    img.src = 'assets/images/moj-logo-rec.bmp';
    // draw image (for raster image)
    const { context } = this.canvasInitialize(
      'assets/images/moj-logo-rec.bmp',
      {
        dx: 0,
        dy: 0,
        dw: 150,
        dh: 150,
      },
      true
    );

    // append raster image
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addImage(context, 0, 0, 250, 250);
    builder.addFeedLine(1);
  }
}
