import { Component, OnInit } from '@angular/core';
import { PaymentAuthorisationService } from 'src/app/_services/payment-authorisation.service';

@Component({
  selector: 'app-payment-authorisation',
  templateUrl: './payment-authorisation.component.html',
  styleUrls: ['./payment-authorisation.component.scss'],
})
export class PaymentAuthorisationComponent implements OnInit {
  constructor(
    public paymentAuthorisationService: PaymentAuthorisationService
  ) {}

  ngOnInit(): void {
    this.paymentAuthorisationService.getAuthorisationPin();
  }
}
