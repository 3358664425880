import { Injectable, Output } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { Dispense } from '../../_models/dispense';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class DispenseService {
  // properties
  // ----------
  @Output() dispenses: any;
  @Output() dispenses$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  dispenseQuery$: any;
  constructor(public api: ApiService) {}

  getDispenses() {
    return this.api.getAPI<Dispense>('dispenses').pipe(map((res) => res.body));
    // .subscribe((res: any) => {
    //   this.dispenses = res;
    //   this.dispenses$.next(res);
    // });
  }

  getFilteredDispenses() {
    return this.api.getAPI<Dispense>('dispenses?limit=100&offset=0&status=DISPENSED').pipe(map((res) => res.body));
  }
  

  updateDispense(dispense: Dispense, dispense_id: number) {
    return this.api
      .updateAPI<any>('dispenses', dispense_id, dispense)
      .subscribe((res: any) => {
        console.log('res', res);
      });
  }
}
