import { Injectable } from '@angular/core';
import { fromEvent, map, merge, of, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  networkStatus: boolean = false;
  networkStatusObservable$: Subject<any> = new Subject<any>();
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor() {}

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        console.log('status', status);
        this.networkStatus = status;
        // added this observable
        this.networkStatusObservable$.next(status);
      });
  }
}
