<div class="flex flex-column h-full">
  <!-- <div
    *ngIf="
      !activeSaleService.activeInvoice.items && customerService.activeCustomer
    "
  >
    There are no products added to the sale yet
  </div> -->
  <div *ngFor="let invoiceItem of activeSaleService.activeInvoice.items">
    <!-- <p>{{ item.description }}</p> -->
    <ul class="list-none p-0 m-0 h-full">
      <li class="flex align-items-center mb-4">
        <div class="flex-auto px-3">
          <div class="flex align-items-center justify-content-between mb-3">
            <span class="text-900 font-medium">{{
              invoiceItem.description
            }}</span>
            <span class="text-900 font-bold">{{
              invoiceItem.price_inclusive | currency : "R"
            }}</span>
          </div>
          <div class="text-600 text-sm mb-3">
            SKU | <b>{{ invoiceItem.product_code }}</b>
          </div>
          <div
            class="flex flex-auto justify-content-between align-items-center"
          >
            <p-inputNumber
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              mode="decimal"
              inputStyleClass="w-12rem text-center py-2 px-1 border-transparent"
              [(ngModel)]="invoiceItem.product_quantity"
              class="border-1 surface-border border-round"
              decrementButtonClass="p-button-text py-1 px-1"
              incrementButtonClass="p-button-text py-1 px-1"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              (ngModelChange)="activeSaleService.calculateInvoiceTotals()"
              (onFocus)="invoiceItem.product_quantity = null"
              [maxFractionDigits]="2"
              [minFractionDigits]="2"
              [suffix]="' ' + invoiceItem.product_unit | lowercase"
              [disabled]="
                selectionService.selected &&
                invoiceItem.description == 'M Diesel- 50ppm'
                  ? true
                  : false
              "
            ></p-inputNumber>

            <button
              pButton
              pRipple
              icon="pi pi-trash"
              class="p-button-danger p-button-text p-button-rounded"
              (click)="
                activeSaleService.removeProductFromInvoice(invoiceItem);
                removeInvoiceItems(invoiceItem);
                selectionService.selected = false
              "
            ></button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="border-top-1 surface-border p-3">
    <div class="flex align-items-center justify-content-between mb-3">
      <span class="text-900 font-medium"
        >Total Amount <span class="text-600 text-sm">incl. VAT</span></span
      >
      <span class="text-900 font-bold">{{
        activeSaleService.activeInvoice.amount_total_inclusive | currency : "R"
      }}</span>
    </div>
    <div *ngIf="activeSaleService.activeInvoice.amount_total_inclusive > 0">
      <button
        pButton
        pRipple
        class="p-button-success mb-3 w-full"
        label="Complete Transaction"
        [disabled]="
          !customerService.activeCustomer ||
          (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
            !validationService.doesActiveCustomerHaveCredit()) ||
          (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
            !activeSaleService.canActiveCustomerAffordTheOrder())
        "
        (click)="this.staticDialogService.openTransactionCompleteDialog()"
      ></button>

      <button
        pButton
        pRipple
        class="p-button-secondary mb-3 w-full"
        label="Override Limit"
        *ngIf="
          !customerService.activeCustomer ||
          (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
            !validationService.doesActiveCustomerHaveCredit()) ||
          (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
            !activeSaleService.canActiveCustomerAffordTheOrder())
        "
        (click)="initiateOverrideCreditLimit()"
      ></button>
      <!-- <button
      pButton
      pRipple
      class="p-button-outlined p-button-secondary w-full"
      label="Continue Shopping"
      pStyleClass="#slideover-cart"
      leaveToClass="hidden"
      leaveActiveClass="fadeoutright"
    ></button> -->
    </div>
  </div>
</div>
