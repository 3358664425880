// https://stackoverflow.com/questions/52200963/typescript-enum-with-multiple-string-values

export class CustomerCategory {
  public static readonly CashOnDelivery = new CustomerCategory(0, 'cod', 0);
  public static readonly Account7Days = new CustomerCategory(7, '7 days', 10);
  public static readonly Account14Days = new CustomerCategory(
    14,
    '14 days',
    17
  );
  public static readonly Account21Days = new CustomerCategory(
    21,
    '21 days',
    24
  );
  public static readonly Account30Days = new CustomerCategory(
    30,
    '30 days',
    33
  );
  public static readonly Account45Days = new CustomerCategory(
    45,
    '45 days',
    48
  );

  private constructor(
    public readonly days: number,
    public readonly categoryDescription: string,
    public readonly daysGrace: number
  ) {}
}
