import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { ThermalPrintModule } from 'ng-thermal-print'; //add this line
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule, MatNavList } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
// import { TableComponent } from './main-application/table/table.component';
import { HomeComponent } from './main-application/home/home.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
// import { ThingstodoComponent } from './main-application/thingstodo/thingstodo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { BadgeModule } from 'primeng/badge';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { LoginComponent } from './auth/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { ChartModule } from 'primeng/chart';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { SaleComponent } from './main-application/till/sale/sale.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InvoiceCreateComponent } from './main-application/invoice/invoice-create/invoice-create.component';
import { CustomerSelectComponent } from './main-application/customer/customer-select/customer-select.component';

import { DropdownModule } from 'primeng/dropdown';
import { CustomerDetailsComponent } from './main-application/till/customer-details/customer-details.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { SettingsComponent } from './main-application/settings/settings.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SalesViewComponent } from './main-application/till/sales-view/sales-view.component';
import { TillComponent } from './main-application/till/till.component';
import { TransactionCompleteComponent } from './main-application/till/transaction-complete/transaction-complete.component';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipModule } from 'primeng/chip';
import { ManipulateUserComponent } from './main-application/settings/manipulate-user/manipulate-user.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { AutoFocusModule } from 'primeng/autofocus';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';

import { MessageService } from 'primeng/api';
import { CreditCheckComponent } from './main-application/credit-check/credit-check.component';
import { ReceiptSettingsComponent } from './main-application/settings/receipt-settings/receipt-settings.component';
import { OnlineStatusService } from './_services/online-status.service';
import { environment } from 'src/environments/environment';
import { PaymentAuthorisationComponent } from './main-application/settings/payment-authorisation/payment-authorisation.component';
import { OverrideLimitComponent } from './main-application/till/override-limit/override-limit.component';
import { DispensesComponent } from './main-application/dispenses/dispenses.component';
import { DashboardComponent } from './main-application/dashboard/dashboard.component';
import { SplitterModule } from 'primeng/splitter';

import { TagModule } from 'primeng/tag';

import { DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoginComponent,
    SaleComponent,
    SettingsComponent,
    TillComponent,
    InvoiceCreateComponent,
    CustomerSelectComponent,
    CustomerDetailsComponent,
    SalesViewComponent,
    TransactionCompleteComponent,
    ManipulateUserComponent,
    CreditCheckComponent,
    ReceiptSettingsComponent,
    PaymentAuthorisationComponent,
    OverrideLimitComponent,
    DispensesComponent,
    DashboardComponent,
  ],
  imports: [
    HttpClientModule,
    ThermalPrintModule,
    TagModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatExpansionModule,
    ButtonModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    DialogModule,
    DividerModule,
    InputNumberModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    SkeletonModule,
    TableModule,
    ChipModule,
    TableModule,
    MessageModule,
    MessagesModule,
    AutoFocusModule,
    ToggleButtonModule,
    ToastModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    BadgeModule,
    RippleModule,
    StyleClassModule,
    SelectButtonModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    PasswordModule,
    SplitterModule,
    DynamicDialogModule,

    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    MessageService,
    OnlineStatusService,
    DialogService,
    DynamicDialogRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
