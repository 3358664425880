import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentAuthorisationService {
  authorisationPinQuery$: any;
  authorisationPin: any;

  userEnteredAuthorisationPin: string = '';

  @Output() authorisationPinObservable$: BehaviorSubject<any> =
    new BehaviorSubject<any>('');

  constructor(public api: ApiService) {}

  public get authorisationPinValue(): any {
    return this.authorisationPinObservable$.value;
  }

  getAuthorisationPin() {
    this.authorisationPinQuery$ = this.api
      .getAPI<any>('get-unique-pin')
      .subscribe((response) => {
        this.authorisationPin = response.body;
        this.authorisationPinObservable$.next(this.authorisationPin);
      });
  }

  generateNewAuthorisationPin() {
    this.authorisationPinQuery$ = this.api
      .getAPI<any>('generate-unique-pin')
      .subscribe((response) => {
        this.authorisationPin = response.body;
        this.authorisationPinObservable$.next(this.authorisationPin);
      });
  }
}
