import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { CustomerCategory } from 'src/app/_models/customer-category';
import { Role } from 'src/app/_models/role';
import { UserService } from 'src/app/_services';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-manipulate-user',
  templateUrl: './manipulate-user.component.html',
  styleUrls: ['./manipulate-user.component.scss'],
})
export class ManipulateUserComponent implements OnInit {
  roles: any;

  selectedRole: Role = Role.Cashier;

  constructor(
    public userService: UserService,
    private fb: FormBuilder,
    public staticDialogService: StaticDialogService,
    public notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.roles = this.getRoleValues();
  }

  onSubmitUserForm() {
    if (this.userService.userForm.valid) {
      // console.log('Form is valid');
      console.log(this.userService.userForm.value);

      this.userService.postNewUser(this.userService.userForm.value);

      this.userService.userForm.reset();
      this.staticDialogService.closeManipulateUserDialog();
      this.notificationService.userAdded();
    } else {
      // console.log('Form is invalid');
      console.log(this.userService.userForm.value);
    }
  }

  getRoleValues() {
    const enumType = Role;
    return Object.keys(enumType)
      .filter((key: any) => !isNaN(Number(key)))
      .map((key: any) => {
        return { role_id: key, role_name: enumType[key] };
      });
  }

  convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }
}
