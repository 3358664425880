<div class="surface-section px-4 py-4 md:px-1 lg:px-2">
  <div class="grid">
    <form
      [formGroup]="this.userService.userForm"
      (ngSubmit)="onSubmitUserForm()"
    >
      <div class="col-12 lg:col-12">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12 md:col-6">
            <label for="first_name" class="font-medium text-900"
              >First Name</label
            >
            <input
              id="first_name"
              type="text"
              pInputText
              formControlName="first_name"
            />
            <div
              class="px-1 py-1"
              *ngIf="
                this.userService.userForm.get('first_name')?.invalid &&
                (this.userService.userForm.get('first_name')?.touched ||
                  this.userService.userForm.get('first_name')?.dirty)
              "
            >
              <small id="first_name-help" class="p-error block"
                ><strong>First Name</strong> is required</small
              >
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="last_name" class="font-medium text-900"
              >Last Name</label
            >
            <input
              id="last_name"
              type="text"
              pInputText
              formControlName="last_name"
            />
            <div
              class="px-1 py-1"
              *ngIf="
                this.userService.userForm.get('last_name')?.invalid &&
                (this.userService.userForm.get('last_name')?.touched ||
                  this.userService.userForm.get('last_name')?.dirty)
              "
            >
              <small id="last_name-help" class="p-error block"
                ><strong>Last Name</strong> is required</small
              >
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="username" class="font-medium text-900">Username</label>
            <input
              id="username"
              type="text"
              pInputText
              formControlName="username"
            />
            <div
              class="px-1 py-1"
              *ngIf="
                !this.userService.userForm.get('username')?.valid &&
                this.userService.userForm.get('username')?.touched
              "
            >
              <small id="last_name-help" class="p-error block"
                ><strong>Username</strong> is required</small
              >
            </div>
          </div>

          <div class="field mb-4 col-12 md:col-6">
            <label for="email" class="font-medium text-900">Email</label>
            <input id="email" type="email" pInputText formControlName="email" />
            <div
              class="px-1 py-1"
              *ngIf="
                this.userService.userForm.get('email')?.invalid &&
                (this.userService.userForm.get('email')?.touched ||
                  this.userService.userForm.get('email')?.dirty)
              "
            >
              <small id="last_name-help" class="p-error block"
                ><strong>Email</strong> is required</small
              >
            </div>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="country" class="font-medium text-900">Role</label>

            <p-dropdown
              inputId="role"
              [options]="roles"
              optionLabel="role_name"
              optionValue="role_id"
              [showClear]="true"
              placeholder="Select a Role"
              [(ngModel)]="selectedRole"
              formControlName="role_id"
            >
              <ng-template let-role pTemplate="item">
                <div class="flex align-items-center">
                  <div>{{ role.role_name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <div
              class="px-1 py-1"
              *ngIf="
                this.userService.userForm.get('role_id')?.invalid &&
                (this.userService.userForm.get('role_id')?.touched ||
                  this.userService.userForm.get('role_id')?.dirty)
              "
            >
              <small id="last_name-help" class="p-error block"
                ><strong>Role</strong> is required</small
              >
            </div>
          </div>

          <div class="field mb-4 col-12 md:col-12">
            <label for="password" class="font-medium text-900">Password</label>
            <input
              id="password"
              type="password"
              pInputText
              formControlName="password"
            />
          </div>

          <!-- <div class="field mb-4 col-12 md:col-12">
            <label for="password" class="font-medium text-900">Password</label>
            <input
              id="password"
              type="password"
              pInputText
              formControlName="password"
            />
          </div> -->

          <div class="col-12">
            <button
              pButton
              pRipple
              type="submit"
              label="Save Changes"
              class="w-auto mt-3"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
