import { Injectable, Output } from '@angular/core';
import { ActiveSaleService } from '../active-sale.service';
import { CustomerService } from '../entities/customer.service';
import { InvoiceService } from '../entities/invoice.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class StaticDialogService {
  @Output() displayInvoiceDialog: boolean;
  displayCustomerSelectDialog: boolean;
  displaySalesViewDialog: boolean;
  displayTransactionCompleteDialog: boolean;
  displayManipulateUserDialog: boolean;
  displayOverrideDialog: boolean;

  constructor(
    private userService: UserService,
    public invoiceService: InvoiceService,
    public activeSaleService: ActiveSaleService,
    public customerService: CustomerService
  ) {
    this.displayCustomerSelectDialog = false;
    this.displayInvoiceDialog = false;
    this.displaySalesViewDialog = false;
    this.displayTransactionCompleteDialog = false;
    this.displayManipulateUserDialog = false;
    this.displayOverrideDialog = false;
  }

  openInvoiceDialog() {
    if (this.customerService.activeCustomer) {
      this.displayInvoiceDialog = true;
      this.activeSaleService.createFromActiveInvoice();
    }
  }

  closeInvoiceDialog() {
    this.displayInvoiceDialog = false;
    // this.activeSaleService.initialiseNewInvoice();
  }

  openTransactionCompleteDialog() {
    if (this.customerService.activeCustomer) {
      // this.activeSaleService.initialiseNewInvoice();
      this.displayTransactionCompleteDialog = true;
    }
  }

  openCustomerSelectDialog() {
    this.displayCustomerSelectDialog = true;
  }

  openSalesViewDialog() {
    this.displaySalesViewDialog = true;
  }

  closeTransactionCompleteDialog() {
    this.displayTransactionCompleteDialog = false;
  }

  openManipulateUserDialog() {
    if (this.userService.currentUserValue.role_id === 1) {
      this.displayManipulateUserDialog = true;
    }
  }
  closeManipulateUserDialog() {
    if (this.userService.currentUserValue.role_id === 1) {
      this.displayManipulateUserDialog = false;
    }
  }

  openOverrideDialog() {
    this.displayOverrideDialog = true;
  }

  closeOverrideDialog() {
    this.displayOverrideDialog = false;
  }
}
