import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/_models/product';
import { UserService } from 'src/app/_services';
import { ApiService } from 'src/app/_services/api.service';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { PaymentAuthorisationService } from 'src/app/_services/payment-authorisation.service';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';
import { ProductPricelistService } from 'src/app/_services/entities/product-pricelist.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  products: any;
  isLoadingProducts: boolean = false;

  constructor(
    public api: ApiService,
    public invoiceService: InvoiceService,
    public customerService: CustomerService,
    public productPricelistService: ProductPricelistService,
    public staticDialogService: StaticDialogService,
    public printerSettings: PrinterSettingsService,
    public userService: UserService,
    public paymentAuthorisationService: PaymentAuthorisationService
  ) {
    this.staticDialogService.displayInvoiceDialog = false;
    this.staticDialogService.displayCustomerSelectDialog = false;
  }

  ngOnInit(): void {
    this.printerSettings.loadSettings();
    this.paymentAuthorisationService.getAuthorisationPin();
    // this.userService.getCurrentUser();
    this.userService.userQuery$.subscribe(() => {});
    this.userService.usersQuery$.subscribe(() => {});
  }
}
