import { Injectable, Output } from '@angular/core';
import { Customer } from '../_models/customer';
import { CustomerCategory } from '../_models/customer-category';
import { CustomerService } from './entities/customer.service';
import { InvoiceService } from './entities/invoice.service';
import { NotificationService } from './notification.service';
import { ProductService } from './entities/product.service';
import { UserService } from './user.service';
import { ValidationService } from './validation.service';
import { v4 as uuidv4 } from 'uuid';
import { Invoice } from '../_models/invoice';
import { BehaviorSubject, Subject } from 'rxjs';
import { SelectionService } from './selection.service';

const customerCategoryMap = {
  [CustomerCategory.CashOnDelivery.categoryDescription]:
    CustomerCategory.CashOnDelivery,
  [CustomerCategory.Account7Days.categoryDescription]:
    CustomerCategory.Account7Days,
  [CustomerCategory.Account14Days.categoryDescription]:
    CustomerCategory.Account14Days,
  [CustomerCategory.Account21Days.categoryDescription]:
    CustomerCategory.Account21Days,
  [CustomerCategory.Account30Days.categoryDescription]:
    CustomerCategory.Account30Days,
  [CustomerCategory.Account45Days.categoryDescription]:
    CustomerCategory.Account45Days,
};

@Injectable({
  providedIn: 'root',
})
export class ActiveSaleService {
  @Output() activeInvoice: Invoice;
  confirmInvoicePayment: boolean = true;
  @Output() confirmInvoicePaymentText: string =
    'I hereby confirm that I recieved the amount or that the order was verified';
  // canAffordTheOrder: boolean;
  @Output() public overrideCreditLimit: boolean;

  @Output() overrideCreditLimitObservable$: BehaviorSubject<any> =
    new BehaviorSubject<any>(false);

  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private invoiceService: InvoiceService,
    private notificationService: NotificationService,
    private userService: UserService,
    private selectionService: SelectionService
  ) {
    this.overrideCreditLimit = false;
    this.setOverrideCreditLimit(false);
    this.activeInvoice = {
      customer_id: 0,
      items: [],
      status: '',
      sage_invoice_id: '',
      date_issued: new Date(),
      date_due: new Date(),
      amount_total_exclusive: 0,
      amount_total_inclusive: 0,
      amount_tax: 0,
      amount_total_due: 0,
      // amount_total: 0,
      // amount_due: 0,
      notes: '',
      invoice_reference: uuidv4(),
      sage_invoice_reference: '',
      sage_sales_rep_id: '',

      // note fields:
      order_no: '',
      truck_reg: '',
      driver_name: '',
      mileage: 0,
      fleet_ref: '',
    };

    // this.canAffordTheOrder = true;
  }

  initialiseNewInvoice() {
    this.setOverrideCreditLimit(false);
    this.confirmInvoicePayment = false;
    this.confirmInvoicePaymentText = `I, ${this.userService.currentUserValue.first_name}, hereby confirm that I recieved the amount or that the order was verified`;
    this.activeInvoice = {
      customer_id: 0,
      items: [],
      status: '',
      sage_invoice_id: '',
      date_issued: new Date(),
      date_due: new Date(),
      amount_total_exclusive: 0,
      amount_total_inclusive: 0,
      amount_tax: 0,
      amount_total_due: 0,
      // amount_total: 0,
      // amount_due: 0,
      notes: '',
      invoice_reference: uuidv4(),
      sage_invoice_reference: '',
      sage_sales_rep_id: '',

      // note fields:
      order_no: '',
      truck_reg: '',
      driver_name: '',
      mileage: 0,
      fleet_ref: '',
    };
    // this.canAffordTheOrder = true;
  }
  //added isdisp
  addProductToInvoice(product: any, isDispense: boolean = false) {
    // console.log('invoice items before adding: ', this.activeInvoice.items);

    let result = -1;
    result = this.activeInvoice.items.findIndex(
      (invoiceItem: any) => invoiceItem.description === product.description
    );

    let activeProductPriceObject = null;
    let customerInfo = this.customerService.getActiveCustomer();

    if (this.productService.getProductPricelistInfo(product.id, customerInfo)) {
      activeProductPriceObject = this.productService.getProductPricelistInfo(
        product.id,
        customerInfo
      );
    } else {
      // default productpricelist does not exist, use default price on item/product
      activeProductPriceObject = this.productService.getDefaultProductPrice(
        product.id
      );
    }

    product.price_inclusive = activeProductPriceObject.price_inclusive;
    product.price_exclusive = activeProductPriceObject.price_exclusive;

    if (result < 0) {
      // the product does not exist on the invoice yet
      this.activeInvoice.items.push({ ...product, product_quantity: 1 });
    } else {
      // the product exists on the invoice, increase quantity
      this.activeInvoice.items[result].product_quantity += 1;
    }
    // console.log('invoice items after adding: ', this.activeInvoice.items);
    this.calculateInvoiceTotals();
  }

  /**
   * Remove a product from the invoice
   *
   * @param product
   */
  removeProductFromInvoice(product: any) {
    // console.log('Remove product from invoice: ', product);
    // console.log('invoice items before removal: ', this.activeInvoice.items);
    let index = -1;
    index = this.activeInvoice.items.findIndex(
      (invoiceItem: any) => invoiceItem.description === product.description
    );

    if (index >= 0) {
      this.activeInvoice.items.splice(index, 1);
    }

    // console.log('invoice items after removal: ', this.activeInvoice.items);

    this.calculateInvoiceTotals();
  }

  calculateInvoiceTotals() {
    this.activeInvoice.amount_total_exclusive = 0;
    this.activeInvoice.amount_total_inclusive = 0;
    let activeProductPriceObject = null;

    for (const item of this.activeInvoice.items) {
      this.activeInvoice.amount_total_inclusive +=
        item.product_quantity * item.price_inclusive;
      console.log('Over here ', item);

      this.activeInvoice.amount_total_exclusive +=
        item.product_quantity * item.price_exclusive;
    }

    if (this.customerService.getActiveCustomer()) {
      this.activeInvoice.date_due = this.determineInvoiceDueDate(
        this.customerService.getActiveCustomer(),
        this.activeInvoice.date_issued
      );

      this.activeInvoice.amount_total_due =
        this.activeInvoice.amount_total_inclusive;

      this.activeInvoice.amount_tax =
        this.activeInvoice.amount_total_inclusive -
        this.activeInvoice.amount_total_exclusive;

      // todo: add check if current total goes over credit limit
      // todo: add authorisation for order if credit limit is exceeded

      if (this.canActiveCustomerAffordTheOrder()) {
        this.notificationService.clearErrors();
      } else {
        this.notificationService.orderNotAffordable();
      }
    } else {
      // todo: implement check for credit when adding customer
    }

    // this.activeInvoice.amount_total = this.activeInvoiceTotal;
    // this.activeInvoice.amount_due = this.activeInvoiceTotal;
  }

  resetProductQuantity() {
    let result = -1;
    result = this.activeInvoice.items.findIndex(
      (invoiceItem: any) => invoiceItem.description === 'M Diesel- 50ppm'
    );
    if (result > -1) {
      this.activeInvoice.items[result].product_quantity = 0;
    }
  }

  //Hijacking for diesel for dispenses
  updateProductQuantity(itemQuantity: number) {
    // console.log("product");
    // console.log(itemQuantity);
    //console.log(this.activeInvoice.items);

    let result = -1;
    result = this.activeInvoice.items.findIndex(
      (invoiceItem: any) => invoiceItem.description === 'M Diesel- 50ppm'
    );
    if (result > -1) {
      this.activeInvoice.items[result].product_quantity += itemQuantity;
    }
    // console.log(this.activeInvoice.items);
    this.calculateInvoiceTotals();
  }

  determineInvoiceAmountDue(customer: Customer, amount_total: number) {
    const daysDifference =
      customerCategoryMap[customer.category_description.toLocaleLowerCase()]
        .days;

    let amountDue = 0;

    if (daysDifference === 0) {
      return amountDue;
    } else {
      amountDue = amount_total;
      return amountDue;
    }
  }

  determineInvoiceDueDate(customer: Customer, date_issued: Date) {
    // https://dirask.com/posts/TypeScript-add-days-to-date-pJAaVD
    // chatGPT improved the code yikes

    const daysDifference =
      customerCategoryMap[customer.category_description.toLocaleLowerCase()]
        .days;

    const dateDue = new Date(
      date_issued.getTime() + daysDifference * 24 * 60 * 60 * 1000
    );

    return dateDue;
  }

  canActiveCustomerAffordTheOrder() {
    if (this.activeInvoice && this.activeInvoice.amount_total_inclusive > 0)
      if (
        this.customerService.activeCustomer.creditlimit -
          this.customerService.activeCustomer.balance -
          this.activeInvoice.amount_total_inclusive >
          0 ||
        this.customerService.activeCustomer.category_description.toLocaleLowerCase() ===
          CustomerCategory.CashOnDelivery.categoryDescription
      ) {
        return true;
      } else {
        return false;
      }
    else {
      return true;
    }
  }

  createFromActiveInvoice() {
    this.confirmInvoicePayment = false;
    this.invoiceService.invoiceForm.setValue({
      customer_id: this.activeInvoice.customer_id,
      status: '',
      sage_invoice_id: '',
      date_issued: this.toISOStringWithTimezone(this.activeInvoice.date_issued),
      date_due: this.toISOStringWithTimezone(this.activeInvoice.date_due),
      amount_total: this.activeInvoice.amount_total_inclusive,
      notes: this.createNotesFromSubmission(
        this.invoiceService.notesForm.controls
      ),
      amount_due: this.activeInvoice.amount_total_inclusive,
      invoice_reference: this.activeInvoice.invoice_reference,
      sage_invoice_reference: this.activeInvoice.sage_invoice_reference,
      sage_sales_rep_id: this.userService.getSalesRepId(),
    });
  }

  createNotesFromSubmission(notesForm: any) {
    return `Order No: ${notesForm.order_no.value}\nTruck Reg: ${notesForm.truck_reg.value}\nDriver Name: ${notesForm.driver_name.value}\nMileage: ${notesForm.mileage.value}\nFleet Ref: ${notesForm.fleet_ref.value}`;
  }

  setConfirmInvoicePayment(value: boolean) {
    this.confirmInvoicePayment = value;
  }

  getConfirmInvoicePayment(): boolean {
    return this.confirmInvoicePayment;
  }

  toggleConfirmInvoicePayment() {
    return (this.confirmInvoicePayment = !this.confirmInvoicePayment);
  }

  setOverrideCreditLimit(value: boolean) {
    this.overrideCreditLimit = value;
    this.overrideCreditLimitObservable$.next(this.overrideCreditLimit);
  }

  get overrideCreditLimitValue(): boolean {
    return this.overrideCreditLimitObservable$.value;
  }

  toISOStringWithTimezone(date: any) {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = (n: any) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      diff +
      pad(tzOffset / 60) +
      ':' +
      pad(tzOffset % 60)
    );
  }
}
