<div class="flex relative lg:static surface-ground">
  <div
    id="app-sidebar-5"
    class="bg-gray-900 h-full md:h-screen hidden lg:block absolute lg:sticky left-0 top-0 z-2"
  >
    <div class="flex flex-column h-full">
      <div
        class="flex align-items-center justify-content-center px-5 bg-gray-900 text-bluegray-100 flex-shrink-0 text-lg"
        style="height: 60px"
      ></div>
      <div class="mt-3">
        <ul
          class="list-none p-3 m-0"
          *ngIf="
            this.authenticationService && this.authenticationService.user
              | async
          "
        >
          <!-- <li>
            <a
              pRipple
              type="button"
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              routerLink="/"
            >
              <i
                class="pi pi-home mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Till</span
              >
            </a>
          </li> -->

          <li>
            <a
              pRipple
              type="button"
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-700 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              style="text-decoration: none"
              [routerLinkActive]="'bg-gray-700 text-bluegray-100 border-round'"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/"
            >
              <i
                class="pi pi-money-bill mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-sm lg:block"
                >Home</span
              >
            </a>
            <a
              pRipple
              type="button"
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-700 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              style="text-decoration: none"
              [routerLinkActive]="'bg-gray-700 text-bluegray-100 border-round'"
              routerLink="dashboard"
            >
              <i
                class="pi pi-chart-line mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-sm lg:block"
                >Dashboard</span
              >
            </a>
          </li>

          <!-- <li *ngIf="userService.currentUserValue.role_id == 1">
            <a
              pRipple
              type="button"
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              style="text-decoration: none"
              routerLink="/dashboard"
            >
              <i
                class="pi pi-chart-line mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-sm lg:block"
                >Dashboard</span
              >
            </a>
          </li> -->
          <!-- <li>
            <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
            >
              <i
                class="pi pi-search mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Search</span
              >
            </a>
          </li> -->
          <!-- <li>
            <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
            >
              <i
                class="pi pi-users mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Team</span
              >
            </a>
          </li> -->
          <!-- 
          <li class="relative">
            <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              pStyleClass="@next"
              enterClass="hidden"
              leaveToClass="hidden"
              [hideOnOutsideClick]="true"
            >
              <i
                class="pi pi-chart-line mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
                pBadge="3"
                severity="danger"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Reports</span
              >
              <i class="pi pi-chevron-down ml-auto lg:hidden"></i>
            </a>
            <ul
              class="list-none pl-3 pr-0 py-0 lg:p-3 m-0 lg:ml-3 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-1 bg-gray-900 shadow-none lg:shadow-2 w-full lg:w-15rem"
            >
              <li>
                <a
                  pRipple
                  class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                  pStyleClass="@next"
                  toggleClass="hidden"
                >
                  <i class="pi pi-chart-line mr-2"></i>
                  <span class="font-medium">Revenue</span>
                  <i class="pi pi-chevron-down ml-auto"></i>
                </a>
                <ul
                  class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                >
                  <li>
                    <a
                      pRipple
                      class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                    >
                      <i class="pi pi-table mr-2"></i>
                      <span class="font-medium">View</span>
                    </a>
                  </li>
                  <li>
                    <a
                      pRipple
                      class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                    >
                      <i class="pi pi-search mr-2"></i>
                      <span class="font-medium">Search</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  pRipple
                  class="flex align-items-center cursor-pointer p-3 hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
                >
                  <i class="pi pi-chart-line mr-2"></i>
                  <span class="font-medium">Expenses</span>
                </a>
              </li>
            </ul>
          </li> -->
          <!-- <li>
            <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
            >
              <i
                class="pi pi-calendar mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Events</span
              >
            </a>
          </li> -->
          <!-- <li> -->
          <!-- <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
            >
              <i
                class="pi pi-cog mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Settings</span
              >
            </a> -->
          <!-- </li> -->
        </ul>
      </div>
      <div class="mt-auto">
        <ul
          class="list-none p-3 m-0"
          *ngIf="
            this.authenticationService && this.authenticationService.user
              | async
          "
        >
          <li>
            <a
              pRipple
              class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-700 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
              routerLink="/settings"
              style="text-decoration: none"
              [routerLinkActive]="'bg-gray-700 text-bluegray-100 border-round'"
            >
              <i
                class="pi pi-cog mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:block"
                >Settings</span
              >
            </a>
          </li>
        </ul>
        <!-- <hr class="mb-3 mx-3 border-top-1 border-none border-gray-800" />
        <a
          pRipple
          class="m-3 flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:bg-gray-800 border-round text-gray-300 hover:text-white transition-duration-150 transition-colors"
        >
          <img
            src="assets/images/blocks/avatars/circle/avatar-f-1.png"
            class="mr-2 lg:mr-0"
            style="width: 32px; height: 32px"
          />
          <span class="font-medium inline lg:hidden">Amy Elsner</span>
        </a> -->
      </div>
    </div>
  </div>
  <div class="min-h-screen flex flex-column relative flex-auto">
    <div
      class="flex justify-content-between align-items-center px-5 surface-section relative lg:static border-bottom-1 surface-border"
      style="height: 60px"
    >
      <div class="flex">
        <span
          ><img src="/assets/images/moj-logo.png" alt="Image" height="30"
        /></span>
        <a
          pRipple
          class="cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700 mr-3"
          pStyleClass="#app-sidebar-5"
          enterClass="hidden"
          enterActiveClass="fadeinleft"
          leaveToClass="hidden"
          leaveActiveClass="fadeoutleft"
          [hideOnOutsideClick]="true"
        >
          <i class="pi pi-bars text-4xl"></i>
        </a>
        <!-- 

        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            class="border-none w-10rem sm:w-20rem"
            placeholder="Search"
          />
        </span> -->
      </div>
      <a
        pRipple
        class="cursor-pointer block lg:hidden text-700"
        pStyleClass="@next"
        enterClass="hidden"
        enterActiveClass="fadein"
        leaveToClass="hidden"
        leaveActiveClass="fadeout"
        [hideOnOutsideClick]="true"
      >
        <i class="pi pi-ellipsis-v text-2xl"></i>
      </a>
      <ul
        class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
      >
        <!-- int: top menu items -->
        <!-- <li>
          <a
            pRipple
            class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors"
          >
            <i
              class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"
              pBadge
              severity="danger"
            ></i>
            <span class="block lg:hidden font-medium">Notifications</span>
          </a>
        </li> -->

        <li
          *ngIf="
            this.authenticationService && this.authenticationService.userValue
          "
        >
          <a
            pRipple
            class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors"
            (click)="menu.toggle($event)"
          >
            <span class="mr-2"
              >{{ this.userService.currentUserValue.first_name }}
              {{ this.userService.currentUserValue.last_name }}</span
            >
            <i class="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
            <span class="block lg:hidden font-medium">User</span>
          </a>
          <p-menu #menu [popup]="true" [model]="profileitems"></p-menu>
        </li>
      </ul>
    </div>
    <div class="p-5 flex flex-column flex-auto">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<p-toast key="mainApplication" position="top-right"></p-toast>
<p-toast key="mainApplicationErrors" position="top-right"></p-toast>
<!-- 
<p-menubar [model]="mainitems">
  <ng-template pTemplate="start">
    <h2>intentio Base</h2>
  </ng-template>

  <ng-template pTemplate="end">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-user"
      class="p-button-rounded p-button-info"
      (click)="menu.toggle($event)"

      ></button>

   (click)="menu.toggle($event)" -->

<!-- <p-menu #menu [popup]="true" [model]="profileitems"></p-menu>



  </ng-template>
</p-menubar>
<p-sidebar [visible]="false" [modal]="false" [showCloseIcon]="false">
  <p-panelMenu></p-panelMenu
></p-sidebar>  -->
