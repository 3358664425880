<p-table
  #salesview
  [value]="this.combinedObservable$ | async"
  dataKey="id"
  [scrollable]="true"
  scrollHeight="45vh"
  sortMode="single"
  [globalFilterFields]="['customer.name', 'sage_invoice_reference', 'status']"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          placeholder="Search Sales Invoices"
          (input)="salesview.filterGlobal(getInputValue($event), 'contains')"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Invoice Reference</th>
      <th pSortableColumn="customer.name">
        Customer Name<p-sortIcon field="customer.name"></p-sortIcon>
      </th>
      <th>Category</th>
      <th>Status</th>
      <th pSortableColumn="date_issued">
        Date Issued<p-sortIcon field="date_issued"></p-sortIcon>
      </th>
      <th>Date Due</th>
      <th>Amount Total</th>
      <!-- <th>Action</th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-combined>
    <tr>
      <td>{{ combined.sage_invoice_reference }}</td>
      <td>{{ combined.customer.name }}</td>
      <td>{{ combined.customer.category_description }}</td>
      <td>{{ combined.status }}</td>
      <td>{{ combined.date_issued | date : "yyyy-MM-dd" }}</td>
      <td>{{ combined.date_due | date : "yyyy-MM-dd" }}</td>
      <td>{{ combined.amount_total | currency : "R" }}</td>
      <!-- <td>
        <button
          pButton
          (click)="
            this.receiptPrintingService.printReceipt(
              combined,
              combined.customer,
              this.userService.currentUserValue
            )
          "
        >
          Reprint Slip
        </button>
      </td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7" class="align-content-center">
        <p-progressBar
          *ngIf="combinedLoading"
          mode="indeterminate"
        ></p-progressBar>
        <p *ngIf="!combinedLoading">No sale records found.</p>
      </td>
    </tr>
  </ng-template>
</p-table>
