import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { DispenseService } from 'src/app/_services/entities/dispense.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DispensesComponent } from '../dispenses/dispenses.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  dispense: any;
  isLoadingDispenses: boolean = false;
  user: any;
  ref: any;

  constructor(
    public api: ApiService,
    public dispenseService: DispenseService,
    private dialogService: DialogService
  ) {
    // this.dispense = this.dispenseService.getDispenses();
  }

  ngOnInit(): void {
    this.dispense = this.dispenseService.getDispenses();
    console.log('are you seeing me');
    console.log(this.dispense);
  }

  showDispensesDialog($event: any) {
    this.ref = this.dialogService.open(DispensesComponent, {
      header: 'Add Dispenses',
      width: '70%',
      baseZIndex: 10000,
      data: {},
    });
    this.ref.onClose.subscribe((dispenses: any) => {
      console.log('dispenses', dispenses);
    });
  }
}
