<!-- <ng-container *ngIf="userService.currentUserValue.role_id === 1">
  <p-button [disabled]="true">Add New Customer</p-button>
</ng-container> -->

<p-table
  #customerselect
  [value]="this.customerService.customersObservable$ | async"
  selectionMode="single"
  class="table"
  [(selection)]="selectedCustomer"
  dataKey="id"
  sortMode="single"
  (onRowSelect)="setActiveInvoiceCustomer(selectedCustomer.id)"
  [globalFilterFields]="['name', 'contact_name', 'category_description']"
  [scrollable]="true"
  scrollHeight="60vh"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          placeholder="Search Customers"
          (input)="
            customerselect.filterGlobal(getInputValue($event), 'contains')
          "
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name">
        Name <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="category_description">
        Category <p-sortIcon field="category_description"></p-sortIcon>
      </th>
      <th
        *ngIf="
          userService.getRoleValue(userService.currentUserValue.role_id) ==
          ('Admin' || 'BackOffice')
        "
      >
        Credit Limit
      </th>
      <th
        *ngIf="
          userService.getRoleValue(userService.currentUserValue.role_id) ==
          ('Admin' || 'BackOffice')
        "
      >
        Credit Balance
      </th>

      <th>Contact Name</th>
      <!-- <th>Email</th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer>
    <tr
      [pSelectableRow]="customer"
      [class.nocredit]="
        !this.validationService.doesCustomerHaveCredit(customer.id)
      "
    >
      <td>{{ customer.name }}</td>
      <td>{{ customer.category_description }}</td>
      <td
        *ngIf="
          userService.getRoleValue(userService.currentUserValue.role_id) ==
          ('Admin' || 'BackOffice')
        "
      >
        {{ customer.creditlimit | currency : "R" }}
      </td>
      <td
        *ngIf="
          userService.getRoleValue(userService.currentUserValue.role_id) ==
          ('Admin' || 'BackOffice')
        "
      >
        {{ customer.balance | currency : "R" }}
      </td>
      <td>{{ customer.contact_name }}</td>
      <!-- <td>{{ customer.email }}</td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="align-content-center">
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="customerService.loadingCustomers"
          mode="indeterminate"
        ></p-progressBar>
        <p
          styleClass="align-self-center"
          *ngIf="!customerService.loadingCustomers"
        >
          No customer records found.
        </p>
      </td>
    </tr>
  </ng-template>
</p-table>
