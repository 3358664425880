import { Component, OnInit } from '@angular/core';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { PaymentAuthorisationService } from 'src/app/_services/payment-authorisation.service';

@Component({
  selector: 'app-override-limit',
  templateUrl: './override-limit.component.html',
  styleUrls: ['./override-limit.component.scss'],
})
export class OverrideLimitComponent implements OnInit {
  constructor(
    public paymentAuthorisationService: PaymentAuthorisationService,
    public notificationService: NotificationService,
    public activeSaleService: ActiveSaleService,
    public customerService: CustomerService,
    public staticDialogService: StaticDialogService
  ) {}

  ngOnInit(): void {}

  authoriseOverrideLimit() {
    if (
      this.paymentAuthorisationService.userEnteredAuthorisationPin ===
      this.paymentAuthorisationService.authorisationPin
    ) {
      console.log('Authorisation successful');
      this.activeSaleService.setOverrideCreditLimit(true);
      console.log(
        !this.activeSaleService.canActiveCustomerAffordTheOrder() ||
          !this.customerService.activeCustomer
      );
      this.notificationService.transactionOverrideLimitPassed();
      this.staticDialogService.closeOverrideDialog();
      return true;
    } else {
      console.log('Authorisation failed');
      this.notificationService.transactionOverrideLimitFailed();
      this.staticDialogService.closeOverrideDialog();
      return false;
    }
  }
  onKeyDownFunction(event: any) {
    if (event.key === 'Enter') {
      this.authoriseOverrideLimit();
    }
  }
}
