<div class="" *ngIf="customerService.activeCustomer">
  <div class="shadow-2 surface-card border-round p-3 h-full">
    <!-- <span class="block text-900 mb-4 font-medium text-xl">{{
      customerService.activeCustomer?.name
    }}</span> -->

    <ul class="list-none p-0 m-0">
      <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
        <div class="text-500 w-6 md:w-3 font-medium">Selected Customer</div>
        <div
          class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 text-2xl"
        >
          {{ customerService.activeCustomer?.name }}
        </div>
        <div class="text-900 w-3 md:w-1 md:flex-order-0 flex-order-1">
          <button
            pButton
            pRipple
            icon="pi pi-times"
            class="p-button-danger p-button-text p-button-rounded"
            (click)="clearCustomer()"
          ></button>
        </div>
      </li>
      <!-- <li
        class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
      >
        <div class="text-500 w-6 md:w-3 font-medium">Credit Balance</div>
        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
          {{ customerService.activeCustomer.balance | currency : "R" }}
        </div>
      </li>
      <li
        class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
      >
        <div class="text-500 w-6 md:w-3 font-medium">Credit Limit</div>
        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
          {{ customerService.activeCustomer.creditlimit | currency : "R" }}
        </div>
      </li>
      <li
        class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
      >
        <div class="text-500 w-6 md:w-3 font-medium">Contact Details</div>
        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
          <p class="text-900 mt-2 mb-2">
            {{ customerService.activeCustomer.contact_name }}
          </p>
          <p class="text-900 mt-2 mb-2">
            {{ customerService.activeCustomer.email }}
          </p>
          <p class="text-900 mt-2 mb-2">
            {{ customerService.activeCustomer.mobile }}
          </p>
          <p class="text-900 mt-2 mb-2">
            {{ customerService.activeCustomer.telephone }}
          </p>
        </div>
      </li>
      <li
        class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
      ></li> -->
      <!-- <li
        class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap"
      >
        <div class="text-500 w-6 md:w-2 font-medium">Plot</div>
        <div
          class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3"
        >
          A group of professional bank robbers start to feel the heat from
          police when they unknowingly leave a clue at their latest heist.
        </div>
        <div class="w-6 md:w-2 flex justify-content-end">
          <button
            pButton
            pRipple
            label="Edit"
            icon="pi pi-pencil"
            class="p-button-text"
          ></button>
        </div>
      </li> -->
    </ul>
  </div>
</div>
