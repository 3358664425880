import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/_models/product';
import { UserService } from 'src/app/_services';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { ApiService } from 'src/app/_services/api.service';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { DataSyncService } from 'src/app/_services/data-sync.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { ProductPricelistService } from 'src/app/_services/entities/product-pricelist.service';
import { ProductService } from 'src/app/_services/entities/product.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/_services/notification.service';
import { PaymentAuthorisationService } from 'src/app/_services/payment-authorisation.service';
import { ReceiptPrintingService } from 'src/app/_services/receipt-printing.service';
import { SelectionService } from 'src/app/_services/selection.service';
import { DispensesComponent } from '../dispenses/dispenses.component';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-till',
  templateUrl: './till.component.html',
  styleUrls: ['./till.component.scss'],
})
export class TillComponent implements OnInit {
  products: any;
  isLoadingProducts: boolean = false;
  user: any;
  hasSelectedProduct: boolean = false;
  selectedProduct: any[] = [];
  ref: any;

  dispenses = [];

  isDivVisible = false;
  toggleVisibility() {
    this.isDivVisible = !this.isDivVisible;
  }
  visible1: boolean = false;

  // refreshSelectedRows(): void {
  //   // Call the service method to reset selected rows
  //   this.selectionService.resetSelectedRows();
  // }

  constructor(
    private dialogService: DialogService,
    public api: ApiService,
    public activeSaleService: ActiveSaleService,
    public invoiceService: InvoiceService,
    public customerService: CustomerService,
    public productPricelistService: ProductPricelistService,
    public productService: ProductService,
    public staticDialogService: StaticDialogService,
    public userService: UserService,
    public validationService: ValidationService,
    public dataSyncService: DataSyncService,
    public paymentAuthorisationService: PaymentAuthorisationService,
    public receiptPrintingService: ReceiptPrintingService,
    private selectionService: SelectionService
  ) {
    this.staticDialogService.displayInvoiceDialog = false;
    this.staticDialogService.displayCustomerSelectDialog = false;
  }

  ngOnInit(): void {
    this.customerService.clearActiveCustomer();
    this.productPricelistService.getProductPricelists();
    this.userService.userQuery$.subscribe(() => {});
    this.invoiceService.getInvoices();
    this.paymentAuthorisationService.getAuthorisationPin();
    this.receiptPrintingService.ensureConnected();
  }

  initiateOverrideCreditLimit() {
    this.paymentAuthorisationService.userEnteredAuthorisationPin = '';
    this.staticDialogService.displayOverrideDialog = true;
  }

  showDispensesDialog($event: any) {
    this.ref = this.dialogService.open(DispensesComponent, {
      header: 'Add Dispenses',
      width: '70%',
      baseZIndex: 10000,
      data: {},
    });
    this.ref.onClose.subscribe((dispenses: any) => {
      console.log('dispenses', dispenses);
    });
  }

  handleSelectedProduct(product: Product) {
    if (product.description === 'M Diesel- 50ppm') {
      this.selectedProduct.push(product);
      console.log('this is a selected product', this.selectedProduct);
    } else return;
  }
}
