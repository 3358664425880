import { Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';
import { Customer } from '../../_models/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // properties
  // ----------
  @Output() customers: any[];
  customerText: any;
  customerStrings: any;

  customerQuery$: any;

  loadingCustomers: boolean = false;

  @Output() activeCustomer: any = null;
  @Output() customersObservable$: Subject<any> = new Subject<any>();

  constructor(public api: ApiService) {
    this.customers = [];
  }

  getCustomers() {
    this.loadingCustomers = true;
    this.customerQuery$ = this.api
      .getAPI<Customer>('customer')
      .subscribe((response) => {
        this.customers = response.body;

        this.loadingCustomers = false;
        this.customersObservable$.next(sortCustomers(this.customers));
      });
  }
  clearActiveCustomer() {
    this.activeCustomer = null;
  }
  setActiveCustomer(customerId: any) {
    this.activeCustomer = this.customers?.filter((customer: any) => {
      return customer.id === customerId;
    })[0];

    // if the customer has multiple emails, only use the first one
    if (this.activeCustomer.email.includes(';')) {
      let firstListedEmail = this.activeCustomer.email.split(';')[0];

      this.activeCustomer.email = firstListedEmail;
    }
  }

  // let customerInfo = this.customers.filter((customer: any) => {
  //   return +customer.id === +this.invoiceService.activeInvoice.customer_id;
  // })[0];

  getActiveCustomer() {
    return this.activeCustomer;
  }

  searchCustomers($event: any) {
    this.customerStrings = this.customers
      .filter((customer: any) => {
        return customer.name.toLowerCase().includes($event.query.toLowerCase());
      })
      .map((customer: any) => {
        return customer.name;
      });
  }
}
function sortCustomers(customers: any[]): any {
  customers.sort((a, b) => (a.name > b.name ? 1 : -1));
  return customers;
}
