import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { concat, map, TimeoutError } from 'rxjs';
import { Customer } from 'src/app/_models/customer';
import { Invoice } from 'src/app/_models/invoice';
import { UserService } from 'src/app/_services';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { ApiService } from 'src/app/_services/api.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { OfflineQueueService } from 'src/app/_services/offline-queue.service';
import { OnlineStatusService } from 'src/app/_services/online-status.service';
import { ReceiptPrintingService } from 'src/app/_services/receipt-printing.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { CustomerService } from '../../../_services/entities/customer.service';
import { SelectionService } from '../../../_services/selection.service';
import { DispenseService } from 'src/app/_services/entities/dispense.service';

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.scss'],
})
export class InvoiceCreateComponent implements OnInit {
  invoiceForm!: FormGroup;
  invoiceQuery$: any;

  invoiceRequests: any = [];
  invoiceLinesRequests: any = [];
  invoiceSyncRequests: any = [];

  returnedInvoice: any;

  isLoadingInvoice = false;

  imgSrc = 'assets/images/moj-logo.png';

  customersToPrintExtraReceipt = ['cash', 'nci', 'my fuel'];

  constructor(
    private api: ApiService,
    public activeSaleService: ActiveSaleService,
    private offlineQueueService: OfflineQueueService,
    public invoiceService: InvoiceService,
    public customerService: CustomerService,
    public validationService: ValidationService,
    public staticDialogService: StaticDialogService,
    public notificationService: NotificationService,
    public receiptPrintingService: ReceiptPrintingService,
    public userService: UserService,
    public router: Router,
    public onlineStatusService: OnlineStatusService,
    private selectionService: SelectionService,
    public dispenseService: DispenseService
  ) { }

  ngOnInit(): void {
    // this.invoiceForm = this.invoiceService.invoiceForm;
  }

  convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }

  onSubmit() {
    console.log("Im still active");
    
    this.selectionService.selected = false
    if (this.selectionService.selectedRows$) {

      let something = this.selectionService.selectedRows$.subscribe((selectedRows) => {
        selectedRows.forEach((selectedRow) => {
          selectedRow.status = 'COMPLETED';
          this.dispenseService.updateDispense(selectedRow, selectedRow.id);
        });
      });

      something.unsubscribe();
    }

    

    this.returnedInvoice = {};

    // 2. generate invoice object to be posted to the back end (not strictly necessary)

    let invoiceFromForm = this.invoiceService.invoiceForm.value;
    let invoiceItems = this.activeSaleService.activeInvoice.items;
    let invoiceNotes = this.activeSaleService.activeInvoice.notes;
    let invoiceNotesFromForm = this.invoiceService.notesForm.value;
    let invoiceFromFormWithItemsandNotes = {
      ...invoiceFromForm,
      items: invoiceItems,
      noteFields: invoiceNotesFromForm,
    };

    // 3. post invoice to the back end

    this.isLoadingInvoice = true;

    const invoiceRequest$ = this.offlineQueueService
      .tryPostAPI<any>('invoice/add', invoiceFromFormWithItemsandNotes)
      .subscribe(
        (response) => {
          // console.log('invoice created');

          this.returnedInvoice = response.body;

          // console.log('the comments are:', invoiceNotes);

          let comment = '';

          let invoiceLines =
            this.invoiceService.convertActiveInvoiceItemstoInvoiceLines(
              invoiceItems,
              this.returnedInvoice.id,
              invoiceFromForm.notes
            );

          // 4. post invoice lines to the back end

          invoiceLines.forEach((invoiceLine: any) => {
            const obs$ = this.offlineQueueService
              .tryPostAPI('invoiceproductline/add', invoiceLine)
              .pipe(map((response) => response));

            this.invoiceLinesRequests.push(obs$);
          });
        },
        (error: any) => {
          // console.log('there was a booboo');

          // console.log(error);

          if (this.offlineOrBadConnection(error)) {
          } else {
            try {
              this.receiptPrintingService.printReceiptUSB(
                this.activeSaleService.activeInvoice,
                invoiceNotesFromForm,
                this.customerService.activeCustomer,
                this.userService.currentUserValue,
                'Customer',
                ''
              );

              this.receiptPrintingService.printReceiptUSB(
                this.activeSaleService.activeInvoice,
                invoiceNotesFromForm,
                this.customerService.activeCustomer,
                this.userService.currentUserValue,
                'Shop',
                ''
              );
            } catch (error) {
              console.log(error);
            }

            this.activeSaleService.confirmInvoicePayment = false;
            this.invoiceService.notesForm.reset();
            this.activeSaleService.initialiseNewInvoice();
            this.customerService.clearActiveCustomer();
            this.staticDialogService.closeInvoiceDialog();
            this.router.navigate(['/']);
          }

          // todo: add stuff here
        },
        () => {
          // 4. sync invoice to sage

          const invoiceSyncRequest$ = this.api
            .getAPI(`sync-invoice/${this.returnedInvoice.id}`)
            .pipe(map((response) => response));
          const allRequests$ = concat(
            ...this.invoiceLinesRequests,
            invoiceSyncRequest$
          );

          allRequests$.subscribe(
            (response) => {
              if (response.url.includes('sync-invoice')) {
                this.returnedInvoice = response.body;
                console.log('invoice synced');
              }
            },
            () => { },
            () => {
              // fixme: this is dependent on the sync-invoice endpoint returning the invoice
              // 5. print receipt and clear active invoice and send notification

              this.isLoadingInvoice = false;

              this.notificationService.saleCompleted();

              try {
                this.receiptPrintingService.printReceiptUSB(
                  this.activeSaleService.activeInvoice,
                  invoiceNotesFromForm,
                  this.customerService.activeCustomer,
                  this.userService.currentUserValue,
                  'Customer',
                  this.returnedInvoice.sage_invoice_reference
                );

                this.receiptPrintingService.printReceiptUSB(
                  this.activeSaleService.activeInvoice,
                  invoiceNotesFromForm,
                  this.customerService.activeCustomer,
                  this.userService.currentUserValue,
                  'Shop',
                  this.returnedInvoice.sage_invoice_reference
                );

                if (
                  this.customersToPrintExtraReceipt.some((v) =>
                    String(this.customerService.activeCustomer.name)
                      .toLowerCase()
                      .includes(v)
                  )
                ) {
                  this.receiptPrintingService.printReceiptUSB(
                    this.activeSaleService.activeInvoice,
                    invoiceNotesFromForm,
                    this.customerService.activeCustomer,
                    this.userService.currentUserValue,
                    'Shop Cash',
                    this.returnedInvoice.sage_invoice_reference
                  );
                }
              } catch (error) {
                console.timeLog('ERROR', error);
              }

              this.activeSaleService.confirmInvoicePayment = false;
              this.invoiceService.notesForm.reset();
              this.activeSaleService.initialiseNewInvoice();
              this.customerService.clearActiveCustomer();
              this.staticDialogService.closeInvoiceDialog();
              this.router.navigate(['/']);
            }
          );
        }
      );
  }

  logConfirmInvoicePayment() {
    console.log(
      'confirm invoice payment',
      this.activeSaleService.confirmInvoicePayment
    );
  }

  clickConfirmInvoicePayment(event: any) {
    this.activeSaleService.toggleConfirmInvoicePayment();
  }

  offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      this.onlineStatusService.networkStatus
    );
  }

  ngOnDestroy() {
    this.invoiceQuery$?.unsubscribe();
  }
}
