import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { Product } from 'src/app/_models/product';
import { UserService } from 'src/app/_services';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { PricelistService } from 'src/app/_services/entities/pricelist.service';
import { ProductPricelistService } from 'src/app/_services/entities/product-pricelist.service';
import { ProductService } from 'src/app/_services/entities/product.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { CustomerService } from '../../../_services/entities/customer.service';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss'],
})
// todo: clean up, this got ugly really fast
export class CustomerSelectComponent implements OnInit {
  selectedCustomerId: any;
  selectedCustomer: any;
  productPricelist: any;
  pricelists: any;
  products: any;
  customers: any;

  productPricelistQuery$: any;
  pricelistQuery$: any;

  constructor(
    public activeSaleService: ActiveSaleService,
    public customerService: CustomerService,
    private productPricelistService: ProductPricelistService,
    private pricelistService: PricelistService,
    private productService: ProductService,
    private invoiceService: InvoiceService,
    public userService: UserService,
    public validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.customerService.getCustomers();
    this.invoiceService.getInvoices();
    this.productPricelistService.getProductPricelists();
    this.pricelistService.getPricelists();
    this.productService.getProducts();

    this.productService.productsObservable$.subscribe((products: any) => {
      this.products = products;
    });
  }

  setActiveInvoiceCustomer(customerId: any) {
    this.activeSaleService.activeInvoice.customer_id = customerId;

    this.customerService.setActiveCustomer(customerId);

    let customerInfo = this.customerService.getActiveCustomer();

    if (this.activeSaleService.activeInvoice.items) {
      this.activeSaleService.activeInvoice.items.forEach(
        (product: Product, p_idx: number) => {
          // set the price of the product
          let productPriceObject = null;

          if (
            this.productService.getProductPricelistInfo(
              product.id,
              customerInfo
            )
          ) {
            productPriceObject = this.productService.getProductPricelistInfo(
              product.id,
              customerInfo
            );
          } else {
            // deafult productpricelist does not exist, use default price on item/product
            productPriceObject = this.productService.getDefaultProductPrice(
              product.id
            );
          }

          this.activeSaleService.activeInvoice.items[p_idx].price_inclusive =
            productPriceObject?.price_inclusive;
          this.activeSaleService.activeInvoice.items[p_idx].price_exclusive =
            productPriceObject?.price_exclusive;
        }
      );
      this.activeSaleService.calculateInvoiceTotals();
    } else {
      this.activeSaleService.activeInvoice.items = null;
    }
  }

  getCustomerPriceList(customer_id: number) {}

  clear(table: Table) {
    table.clear();
  }

  getInputValue(e: any): any {
    const inputelement = e.target as HTMLInputElement;
    return inputelement.value;
  }

  ngOnDestroy() {
    this.productPricelistQuery$?.unsubscribe();
    this.pricelistQuery$?.unsubscribe();
  }
}
