import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductPricelist } from '../../_models/product-pricelist';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductPricelistService {
  // properties
  // ----------
  @Output() productPricelist: any;
  @Output() productPricelistObservable$: Subject<any> = new Subject<any>();

  productPricelistQuery$: any;

  constructor(public api: ApiService) {}

  getProductPricelists() {
    this.productPricelistQuery$ = this.api
      .getAPI<ProductPricelist>('productpricelist')
      .subscribe((response) => {
        this.productPricelist = response.body;
        this.productPricelistObservable$.next(this.productPricelist);
      });
  }

  getPriceIncludingVat(productId: number, customerId: number): number {
    // fixme: the price is not updated even though the product and customer id correspond to the priceitem
    // todo: get the pricelist id from the customer
    let tempprice = 0;
    // if (this.productPricelist) {
    //   this.productPricelist.forEach((productprice: ProductPricelist) => {
    //     if (
    //       +productprice.product_id === +productId &&
    //       +productprice.customer_id === +customerId
    //     ) {
    //       tempprice = productprice.price_inclusive;
    //     }
    //   });
    //   return tempprice;
    //   // return 1; // returns here
    // }
    return 0;
  }
}
