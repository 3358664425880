import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { PaymentAuthorisationService } from 'src/app/_services/payment-authorisation.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { TillComponent } from '../till.component';
import { SelectionService } from 'src/app/_services/selection.service';
import { DispenseService } from 'src/app/_services/entities/dispense.service';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
})
export class SaleComponent implements OnInit {
  saleItems: any;

  constructor(
    public invoiceService: InvoiceService,
    public activeSaleService: ActiveSaleService,
    public customerService: CustomerService,
    public staticDialogService: StaticDialogService,
    public paymentAuthorisationService: PaymentAuthorisationService,
    public validationService: ValidationService,
    public tillComponent: TillComponent,
    public selectionService: SelectionService,
    public dispenseService: DispenseService,
  ) {}

  ngOnInit(): void {}

  initiateOverrideCreditLimit() {
    this.paymentAuthorisationService.userEnteredAuthorisationPin = '';
    this.staticDialogService.displayOverrideDialog = true;
  }

  removeInvoiceItems(invoice: any) {
    this.tillComponent.selectedProduct =
      this.tillComponent.selectedProduct.filter(
        (item) => item.description !== invoice.description
      );
  }
}
