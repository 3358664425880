import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Dispense } from 'src/app/_models/dispense';
import { ApiService } from 'src/app/_services/api.service';
import { DispenseService } from 'src/app/_services/entities/dispense.service';
import { SelectionService } from 'src/app/_services/selection.service';
import { Table } from 'primeng/table';
import { ActiveSaleService } from 'src/app/_services/active-sale.service';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogComponent,
} from 'primeng/dynamicdialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dispenses',
  templateUrl: './dispenses.component.html',
  styleUrls: ['./dispenses.component.scss'],
})
export class DispensesComponent implements OnInit {
  @Input() dispenses: Dispense[] = [];
  loading: boolean = true;

  //added @Input()

  dispenses$: Subject<any[]> = new Subject<any[]>();
  isLoadingDispenses: boolean = false;
  user: any;
  dispenseQuery$: any;

  //Select a row
  // selectedRowIndices: number[] = [];
  selectedDispenses: any;

  // toggleRowSelection(index: number): void {
  //   const isSelected = this.selectedRowIndices.includes(index);

  //   if (isSelected) {
  //     // If row is already selected, remove it from the selection
  //     this.selectedRowIndices = this.selectedRowIndices.filter(
  //       (i) => i !== index
  //     );
  //   } else {
  //     // If row is not selected, add it to the selection
  //     this.selectedRowIndices.push(index);
  //   }
  //   this.emitSelectedRows();
  // }

  constructor(
    public api: ApiService,
    public dispenseService: DispenseService,
    private selectionService: SelectionService,
    private activeSaleService: ActiveSaleService,
    private ref: DynamicDialogRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.isCertainPage()){
      this.dispenseQuery$ = this.dispenseService.getFilteredDispenses().subscribe((dispensesFromResponse) => {
        console.log('are you seeing me');
        this.dispenses = dispensesFromResponse;
        this.dispenses$.next(this.dispenses);
        console.log(this.dispenses);
      });
    }else{
      this.dispenseQuery$ = this.dispenseService
      .getDispenses()
      .subscribe((dispensesFromResponse: any) => {
        console.log('are you seeing me');
        this.dispenses = dispensesFromResponse;
        this.dispenses$.next(this.dispenses);
        console.log(this.dispenses);
      });
    }
  }
  // private emitSelectedRows(): void {
  //   const selectedRows = this.selectedRowIndices.map(
  //     (index) => this.dispenses[index]
  //   );
  //   this.selectionService.setSelectedRows(selectedRows);
  // }
  ngOnDestroy() {
    this.dispenseQuery$.unsubscribe();
    this.dispenses$.unsubscribe();
  }

  clear(table: Table) {
    table.clear();
  }

  //log input value everytime it changes
  logValue(value: any) {
    console.log(value);
  }

  onRowSelect($event: any) {
    this.selectionService.setSelectedRows(this.selectedDispenses);
  }

  addDispItems($event: any) {
    // this.activeSaleService.addProductToInvoice();

    this.activeSaleService.resetProductQuantity();
    let selected = this.selectionService.selectedRows$.subscribe(
      (selectedRows) => {
        console.log('Selected Rows:', selectedRows);
        selectedRows.forEach((dispItem) => {
          try {
            // this.activeSaleService.addProductToInvoice(dispItem, true);
            this.activeSaleService.updateProductQuantity(
              dispItem.dispense_litres
            );
          } catch (error) {
            console.log('failed');
            console.log(error);
          }
        });
        // Do whatever you need with the selected rows in the parent component
      }
    );
    selected.unsubscribe();
    this.ref.close();
  }

  isCertainPage(): boolean {
    // Check if the current route matches the desired path
    return this.router.url === '/till';
  }

  

  onRowUnselect($event: any) {
    const unselectedRow = $event.data; // Assuming $event.data contains the unselected row
    this.selectionService.resetSelectedRows(unselectedRow);
  }

  setRowSelected(): boolean {
    if (this.selectionService.selectedRows$ !== null) {
      this.selectionService.selected = true;
    }
    return false;
  }
}
