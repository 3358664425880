import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Role } from '../_models/role';

import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.userValue;
    if (user) {
      // logged in

      const currentUser = this.userService.currentUserValue;
      if (currentUser) {
        // logged in
        console.log('currentUser', currentUser);
        // console.log(route.data['roles']![0]);
        if (
          route.data['roles'] &&
          route.data['roles']!.indexOf(currentUser.role_id) === -1
        ) {
          // role not authorised so redirect to home page
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }
    }

    // authorised so return true

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
