<div class="surface-ground">
  <div class="grid">
    <div class="col-12 md:col-6 p-3">
      <div class="surface-card shadow-2 border-round p-4">
        <div class="flex border-bottom-1 surface-border pb-4">
          <div class="flex flex-column align-items-start">
            <span class="text-xl text-900 font-medium mb-1">Dispenses Table</span>
            <span class="text-600 font-medium mb-2">View Dispenses Table</span>
            <span
              class="bg-blue-50 text-blue-400 border-round inline-flex py-1 px-2 text-sm"
              >Dispenses</span
            >
          </div>
        </div>
        <div class="flex justify-content-between pt-4">
          <button
            pButton
            pRipple
            label="View"
            icon="pi pi-search"
            class="p-button-outlined w-6 mr-2"
            (click)="showDispensesDialog($event)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
