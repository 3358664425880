import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { CreditCheckComponent } from './main-application/credit-check/credit-check.component';
import { HomeComponent } from './main-application/home/home.component';
import { SettingsComponent } from './main-application/settings/settings.component';
import { TillComponent } from './main-application/till/till.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models/role';
import { DashboardComponent } from './main-application/dashboard/dashboard.component';
import { DispensesComponent } from './main-application/dispenses/dispenses.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'creditcheck',
    component: CreditCheckComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'till',
    component: TillComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.BackOffice] },
  },
  {
    path: 'dispenses',
    component: DispensesComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.BackOffice] },
  },
  // {
  //   path: 'dashboard',
  //   component: HomeComponent,
  //   pathMatch: 'full',
  //   canActivate: [AuthGuard],
  //   data: { roles: [Role.Admin, Role.BackOffice] },
  // },
  {
    path: 'settings',
    component: SettingsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Cashier] },
  },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
