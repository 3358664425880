import { Component, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  mainitems: MenuItem[];
  profileitems: MenuItem[];
  @Input() token: any = this.authenticationService.userValue;
  @Input() currentUser = this.userService.currentUserValue;

  showFiller = false;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public messageService: MessageService,
    public notificationService: NotificationService
  ) {
    this.mainitems = [{ label: 'Login', routerLink: 'login' }];
    this.authenticationService.user.subscribe((userValue) => {
      if (userValue) {
        this.mainitems = [
          { label: 'Home', routerLink: '/' },
          // { label: 'Things To Do', routerLink: 'thingstodo' },
          // { label: 'Table', routerLink: 'table' },
          // { label: 'Login', routerLink: 'login' },
        ];
      } else {
        this.mainitems = [
          // { label: 'Home', routerLink: '/' },
          // { label: 'Things To Do', routerLink: 'thingstodo' },
          // { label: 'Table', routerLink: 'table' },
          { label: 'Login', routerLink: 'login' },
        ];
      }
    });

    this.profileitems = [
      {
        label: 'Logout',
        command: () => {
          this.authenticationService.logout();
          this.userService.getCurrentUser();
        },
      },
    ];
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );
}
