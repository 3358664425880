import { Injectable } from '@angular/core';
import { Customer } from '../_models/customer';
import { Invoice } from '../_models/invoice';
import { ActiveSaleService } from './active-sale.service';
import { CustomerService } from './entities/customer.service';
import { InvoiceService } from './entities/invoice.service';
import { CustomerCategory } from '../_models/customer-category';

const GRACE_PERIOD_IN_DAYS = 3; //days;
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    public invoiceService: InvoiceService,
    public activeSaleService: ActiveSaleService,
    public customerService: CustomerService
  ) {}

  doesAnOrderExist() {}

  doesCustomerHaveCredit(customerId: number) {
    let customerInfo = this.customerService.customers.filter(
      (customer: any) => {
        return customer.id === customerId;
      }
    );
    if (
      customerInfo[0].creditlimit - customerInfo[0].balance > 0 ||
      customerInfo[0].category_description.toLocaleLowerCase() ===
        CustomerCategory.CashOnDelivery.categoryDescription
    ) {
      return true;
    } else {
      return false;
    }
  }

  doesActiveCustomerHaveCredit() {
    let customerId = this.customerService.activeCustomer.id;
    return this.doesCustomerHaveCredit(customerId);
  }

  doesActiveCustomerHaveOverdueInvoices() {
    // todo: implement overdue invoices check
    let customerId = this.customerService.activeCustomer.id;
    return this.doesCustomerHaveOverdueInvoices(customerId);
  }

  doesCustomerHaveOverdueInvoices(customerId: number) {
    let overdue = false;
    let customerInfo = this.customerService.customers.filter(
      (customer: Customer) => {
        return customer.id === customerId;
      }
    );

    let customerInvoices = this.invoiceService.invoices.filter(
      (invoice: Invoice) => {
        return invoice.customer_id === customerId;
      }
    );
    // console.log('customerInvoices: ', customerInvoices);

    let invoicesOverdue = customerInvoices.filter((invoice: Invoice) => {
      return invoice.status.toLowerCase().includes('overdue');
    });

    // console.log('invoicesOverdue: ', invoicesOverdue.length);

    invoicesOverdue = invoicesOverdue.filter((invoice: Invoice) => {
      return invoice.date_due != invoice.date_issued;
    });

    // todo: implement overdue invoices check with 3 days grace period

    invoicesOverdue.forEach((invoice: Invoice) => {
      let date_issued = new Date(invoice.date_issued);
      let date_due_with_grace_period = new Date(invoice.date_due);

      date_due_with_grace_period.setDate(
        date_due_with_grace_period.getDate() + GRACE_PERIOD_IN_DAYS
      );
    });

    let invoicesOverduewithGracePeriod = invoicesOverdue.map((invoice: any) => {
      let date_issued = new Date(invoice.date_issued);
      let date_due_with_grace_period = new Date(invoice.date_due);

      date_due_with_grace_period.setDate(
        date_due_with_grace_period.getDate() + 3
      );

      return {
        ...invoice,
        date_due_with_grace_period: date_due_with_grace_period.toISOString(),
      };
    });

    invoicesOverduewithGracePeriod = invoicesOverduewithGracePeriod.filter(
      (invoice: any) => {
        return (
          new Date(invoice.date_due_with_grace_period).getTime() < Date.now()
        );
      }
    );

    if (
      invoicesOverduewithGracePeriod.length > 0 &&
      customerInfo[0].category_description.toLocaleLowerCase() !==
        CustomerCategory.CashOnDelivery.categoryDescription
    ) {
      overdue = true;
    }

    return overdue;

    // customerInvoices.forEach((customerInvoice: Invoice) => {
    //   console.log(new Date(customerInvoice.date_due).getTime(), Date.now());
    //   if (new Date(customerInvoice.date_due).getTime() < Date.now()) {
    //     console.log('they have overdue invoices');
    //     overdue = true;
    //   }
    // });
    // return overdue;
  }
}
