<div class="grid" style="width: 100%">
  <div class="col-12">
    <div *ngIf="false" class="surface-card p-4 shadow-2 border-round">
      <div class="text-3xl font-medium text-900 mb-3">General Settings</div>
      <div class="font-medium text-900 mb-3">
        <!-- <p>This is the settings component</p> -->
        <p>
          Your role is:
          <strong>{{ userService.getRoleValue() }}</strong
          >.
        </p>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="text-3xl font-medium text-900 mb-3">Current User</div>
      <div class="font-medium text-xl text-900 mb-3">
        <p>
          {{ userService.currentUserValue.first_name }}
          {{ userService.currentUserValue.last_name }}
        </p>
      </div>
      <div class="font-medium text-900 mb-3">
        <!-- <p>This is the settings component</p> -->

        <p>
          Your role is:
          <strong>{{ userService.getRoleValue() }}</strong
          >.
        </p>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="
      userService.getRoleValue(userService.currentUserValue.role_id) ==
      ('Admin' || 'BackOffice')
    "
  >
    <div class="col-6">
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-3xl font-medium text-900 mb-3">Database</div>
        <div class="font-medium text-900 mb-3">
          <!-- <p>This is the settings component</p> -->
          <p>
            Your role is:
            <strong>{{ userService.getRoleValue() }}</strong
            >.
          </p>

          <!-- sync customers -->
          <div class="flex">
            <p-button
              *ngIf="!this.dataSyncService.syncingCustomers"
              (onClick)="this.dataSyncService.syncCustomers()"
              >Sync Customers</p-button
            >
            &nbsp;

            <!-- sync invoices -->
            <p-button
              *ngIf="!this.dataSyncService.syncingInvoices"
              (onClick)="this.dataSyncService.syncInvoices()"
              >Sync Invoices</p-button
            >
            &nbsp;

            <!-- sync products -->
            <p-button
              *ngIf="!this.dataSyncService.syncingProducts"
              (onClick)="this.dataSyncService.syncProducts()"
              >Sync Products</p-button
            >
            &nbsp;

            <!-- sync pricelists -->
            <p-button
              *ngIf="!this.dataSyncService.syncingPricelists"
              (onClick)="this.dataSyncService.syncPricelists()"
              >Sync Pricelists</p-button
            >
            &nbsp;

            <!-- sync product pricelists -->
            <p-button
              *ngIf="!this.dataSyncService.syncingProductPricelists"
              (onClick)="this.dataSyncService.syncProductPricelists()"
              >Sync Product Pricelists</p-button
            >
            &nbsp;
            <!-- sync sales representatives -->
            <p-button
              *ngIf="!this.dataSyncService.syncingSalesRepresentatives"
              (onClick)="this.dataSyncService.syncSalesRepresentatives()"
              >Sync Sales Representatives</p-button
            >
            &nbsp;
          </div>

          <p-progressSpinner
            *ngIf="
              dataSyncService.syncingInvoices ||
              dataSyncService.syncingCustomers ||
              dataSyncService.syncingProducts ||
              dataSyncService.syncingPricelists ||
              dataSyncService.syncingProductPricelists ||
              dataSyncService.syncingSalesRepresentatives
            "
          ></p-progressSpinner>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div class="col-6">
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-3xl font-medium text-900 mb-3">Receipt Printer</div>
        <div class="font-medium text-900 mb-3">
          <app-receipt-settings></app-receipt-settings>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      userService.getRoleValue(userService.currentUserValue.role_id) ==
      ('Admin' || 'BackOffice')
    "
  >
    <div class="col-6">
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-3xl font-medium text-900 mb-3">Authorisation Pin</div>
        <div class="font-medium text-900 mb-3">
          <app-payment-authorisation></app-payment-authorisation>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      userService.getRoleValue(userService.currentUserValue.role_id) == 'Admin'
    "
  >
    <div class="col-12">
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-3xl font-medium text-900 mb-3">System Users</div>
        <div class="font-medium text-900 mb-3">
          <p-button
            styleClass="p-button-sm"
            (onClick)="this.staticDialogService.openManipulateUserDialog()"
            >Add new User</p-button
          >
          <!-- <p>This is the settings component</p> -->
          <!-- <p>
            Your role is:
            <strong>{{ getRoleValue() }}</strong
            >.
          </p> -->
          <p-table
            [value]="this.userService.allUsers | async"
            selectionMode="single"
            class="table"
            dataKey="id"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
              <tr>
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>{{ user.username }}</td>
                <td>{{ user.email }}</td>
                <td>{{ userService.getRoleValue(user.role_id) }}</td>
                <!-- <td>{{ customer.category_description }}</td>
                <td>{{ customer.creditlimit | currency : "R" }}</td>
                <td>{{ customer.balance | currency : "R" }}</td>
                <td>{{ customer.contact_name }}</td>
                <td>{{ customer.email }}</td> -->
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- add new user dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displayManipulateUserDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '60vw' }"
    header="Add/Edit System User"
    [draggable]="false"
    [resizable]="false"
  >
    <!-- <div class="flex flex-auto border-top-1 surface-border pt-4"> -->
    <!-- <app-customer-select></app-customer-select> -->
    <app-manipulate-user></app-manipulate-user>

    <!-- </div> -->
    <!-- <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3">
        <button
          pButton
          pRipple
          icon="pi pi-times"
          (click)="this.staticDialogService.displayManipulateUserDialog = false"
          label="Cancel"
          class="p-button-text"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-check"
          (click)="this.staticDialogService.displayManipulateUserDialog = false"
          label="OK"
        ></button>
      </div>
    </ng-template> -->
  </p-dialog>

  <p-dialog
    [(visible)]="this.staticDialogService.displayManipulateUserDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '60vw' }"
    header="Add/Edit System User"
    [draggable]="false"
    [resizable]="false"
  >
    <!-- <div class="flex flex-auto border-top-1 surface-border pt-4"> -->
    <!-- <app-customer-select></app-customer-select> -->
    <app-manipulate-user></app-manipulate-user>

    <!-- </div> -->
    <!-- <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button
        pButton
        pRipple
        icon="pi pi-times"
        (click)="this.staticDialogService.displayManipulateUserDialog = false"
        label="Cancel"
        class="p-button-text"
      ></button>
      <button
        pButton
        pRipple
        icon="pi pi-check"
        (click)="this.staticDialogService.displayManipulateUserDialog = false"
        label="OK"
      ></button>
    </div>
  </ng-template> -->
  </p-dialog>
</div>
