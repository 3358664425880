import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnlineStatusService } from './_services/online-status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'moj-front-end';

  constructor(public onlineStatusService: OnlineStatusService) {}

  ngOnInit(): void {
    this.onlineStatusService.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.onlineStatusService.networkStatus$.unsubscribe();
  }
}
