import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DataSyncService {
  // sync flags
  syncingCustomers: boolean;
  syncingInvoices: boolean;
  syncingProducts: boolean;
  syncingProductPricelists: boolean;
  syncingPricelists: boolean;
  syncingSalesRepresentatives: boolean;

  constructor(public api: ApiService) {
    this.syncingCustomers = false;
    this.syncingInvoices = false;
    this.syncingProducts = false;
    this.syncingProductPricelists = false;
    this.syncingPricelists = false;
    this.syncingSalesRepresentatives = false;
  }

  syncCustomers() {
    this.syncingCustomers = true;
    this.api.getAPI('sync-customers').subscribe((response) => {
      if (response) {
        this.syncingCustomers = false;
      }
    });
  }

  syncInvoices() {
    this.syncingInvoices = true;
    this.api.getAPI('sync-invoices', { limit: 5000 }).subscribe((response) => {
      if (response) {
        this.syncingInvoices = false;
      }
    });
  }

  syncProducts() {
    this.syncingProducts = true;
    this.api.getAPI('sync-products').subscribe((response) => {
      if (response) {
        this.syncingProducts = false;
      }
    });
  }

  syncProductPricelists() {
    this.syncingProductPricelists = true;
    this.api.getAPI('sync-product-pricelists').subscribe((response) => {
      if (response) {
        this.syncingProductPricelists = false;
      }
    });
  }

  syncPricelists() {
    this.syncingPricelists = true;
    this.api.getAPI('sync-pricelists').subscribe((response) => {
      if (response) {
        this.syncingPricelists = false;
      }
    });
  }

  syncSalesRepresentatives() {
    this.syncingSalesRepresentatives = true;
    this.api.getAPI('sync-sales-representatives').subscribe((response) => {
      if (response) {
        this.syncingSalesRepresentatives = false;
      }
    });
  }
}
