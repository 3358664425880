import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrinterSettingsService {
  receiptPrinterSettings: any = {
    timeout: 10000,
    ipAddress: '10.10.10.37',
    useHTTPS: false,
  };

  constructor() {}

  saveSettings() {
    localStorage.setItem(
      'receiptPrinterSettings',
      JSON.stringify(this.receiptPrinterSettings)
    );
  }

  loadSettings() {
    if (localStorage.getItem('receiptPrinterSettings')) {
      this.receiptPrinterSettings = JSON.parse(
        localStorage.getItem('receiptPrinterSettings') as string
      );
    } else {
      localStorage.setItem(
        'receiptPrinterSettings',
        JSON.stringify(this.receiptPrinterSettings)
      );
    }
  }
}
