<div class="grid" style="width: 100%; min-height: 86vh">
  <div class="col-12 md:col-6">
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="text-3xl font-medium text-900 mb-3">Active Sale</div>
      <!-- When removed everything still works so what does it do??? -->
      <div class="flex flex-column h-full mb-4">
        <div class="flex-auto px-3">
          <!-- customer selection -->
          <ng-container *ngIf="this.customerService.activeCustomer">
            <div *ngIf="!this.validationService.doesActiveCustomerHaveCredit()">
              <p-messages severity="error">
                <ng-template pTemplate>
                  <div class="ml-2">
                    <div class="flex align-content-center">
                      <div class="flex-2 align-self-center p-1">
                        <i class="pi pi-exclamation-circle text-2xl"></i>
                      </div>
                      <div class="flex-10 text-lg p-3">
                        This customer,
                        <b>{{ this.customerService.activeCustomer.name }}</b>
                        does not have credit, please ask the driver/patron to
                        contact their accounts department.
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-messages>
            </div>
            <div
              *ngIf="
                this.validationService.doesActiveCustomerHaveOverdueInvoices()
              "
            >
              <p-messages severity="error">
                <ng-template pTemplate>
                  <div class="ml-2">
                    <div class="flex align-content-center">
                      <div class="flex-2 align-self-center p-1">
                        <i class="pi pi-exclamation-circle text-2xl"></i>
                      </div>
                      <div class="flex-10 text-lg p-3">
                        This customer,
                        <b>{{ this.customerService.activeCustomer.name }}</b>
                        has OVERDUE invoices, please ask the driver/patron to
                        contact their accounts department.
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-messages>
            </div>
            <button
              pButton
              pRipple
              class="p-button-secondary mb-3 w-full"
              label="Override"
              *ngIf="
                !customerService.activeCustomer ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !validationService.doesActiveCustomerHaveCredit()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !activeSaleService.canActiveCustomerAffordTheOrder()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  validationService.doesActiveCustomerHaveOverdueInvoices())
              "
              (click)="initiateOverrideCreditLimit()"
            ></button>
          </ng-container>
          <app-customer-details></app-customer-details>
        </div>
      </div>

      <app-sale
        openInvoiceDialog="openInvoiceDialog()"
        parentFun="parentFun()"
      ></app-sale>
    </div>
  </div>
  <!-- This code section displays the select of customers which takes you to another page-->
  <div class="col-12 md:col-6" *ngIf="!this.customerService.activeCustomer">
    <div class="surface-card p-4 shadow-2 border-round">
      <app-customer-select></app-customer-select>
    </div>
  </div>
  <!-- end-->
  <!-- This code section displays the buttons i could not find anywhere-->
  <div class="col-12 md:col-6" *ngIf="this.customerService.activeCustomer">
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="text-3xl font-medium text-900 mb-3">Products</div>

      <div class="grid">
        <ng-container *ngFor="let product of productService.products">
          <div class="col-4" *ngIf="product.active">
            <p-button
              (onClick)="
                activeSaleService.addProductToInvoice(product);
                handleSelectedProduct(product)
              "
              class="p-button-lg"
              styleClass="w-full"
              [label]="product.description"
              [disabled]="
                !customerService.activeCustomer ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !validationService.doesActiveCustomerHaveCredit()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !activeSaleService.canActiveCustomerAffordTheOrder()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  validationService.doesActiveCustomerHaveOverdueInvoices())
              "
            ></p-button>
          </div>
        </ng-container>
      </div>
    </div>
    <br />
    <!-- RB Block thats reveals dispenses table-->
    <div
      *ngIf="this.customerService.activeCustomer && selectedProduct.length > 0"
    >
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-3xl font-medium text-900 mb-3">Dispenses</div>
        <div class="grid">
          <div class="col-6">
            <p-button
              class="p-button-lg"
              styleClass="w-full"
              label="Add Dispenses To Sale"
              (click)="showDispensesDialog($event)"
              [disabled]="
                !customerService.activeCustomer ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !validationService.doesActiveCustomerHaveCredit()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  !activeSaleService.canActiveCustomerAffordTheOrder()) ||
                (!(activeSaleService.overrideCreditLimitObservable$ | async) &&
                  validationService.doesActiveCustomerHaveOverdueInvoices())
              "
            ></p-button>
          </div>
          <!--Col out of 12 either use col 6 for 2 buttons or col 4 3 items-->
        </div>
      </div>
    </div>
    <!--allows it to be underneath products-->
  </div>

  <!-- displays the footer with the view sales button -->
  <div
    *ngIf="userService.currentUserValue.role_id == 1"
    class="col-12 align-self-end"
  >
    <br />
    <div class="surface-card p-4 shadow-2 border-round">
      <p-button
        (onClick)="this.staticDialogService.openSalesViewDialog()"
        class="p-button-lg"
        >View Sales</p-button
      >
    </div>
  </div>

  <!-- dialogs -->
  <!-- invoice dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displayInvoiceDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '65vw' }"
    header="New Invoice"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [blockScroll]="true"
  >
    <div class="flex flex-wrap border-top-1 surface-border pt-4"></div>
    <app-invoice-create></app-invoice-create>

    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3">
        <!-- <p-button
          icon="pi pi-check"
          (click)="this.staticDialogService.displayInvoiceDialog = false"
          label="Ok"
          styleClass="p-button-text"
        ></p-button> -->
      </div>
    </ng-template>
  </p-dialog>

  <!-- customer select dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displayCustomerSelectDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '60vw' }"
    header="Select Customer"
    [draggable]="false"
    [resizable]="false"
  >
    <!-- <div class="flex flex-auto border-top-1 surface-border pt-4"> -->
    <app-customer-select></app-customer-select>
    <!-- </div> -->
    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3">
        <!-- <button
                pButton
                pRipple
                icon="pi pi-times"
                (click)="this.staticDialogService.displayCustomerSelectDialog = false"
                label="Cancel"
                class="p-button-text"
              ></button> -->
        <button
          pButton
          pRipple
          icon="pi pi-check"
          (click)="this.staticDialogService.displayCustomerSelectDialog = false"
          label="OK"
        ></button>
      </div>
    </ng-template>
  </p-dialog>

  <!-- sales dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displaySalesViewDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{
      width: '60vw',
      maxHeight: '80vh',
      overflow: 'hidden',
      minHeight: 0
    }"
    header="View Sales"
    [draggable]="false"
    [resizable]="false"
  >
    <!-- <div
      class="flex flex-wrap border-top-1 surface-border pt-4"
      [style]="{ overflow: 'clip' }"
    > -->
    <app-sales-view></app-sales-view>
    <!-- </div> -->

    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3">
        <p-button
          icon="pi pi-check"
          (click)="this.staticDialogService.displaySalesViewDialog = false"
          label="Ok"
          styleClass="p-button-text"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>

  <!-- transaction complete dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displayTransactionCompleteDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
    header="Complete Transaction"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="flex flex-wrap border-top-1 surface-border pt-4"></div>
    <app-transaction-complete></app-transaction-complete>

    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3"></div>
    </ng-template>
  </p-dialog>

  <!-- override limit dialog -->
  <p-dialog
    [(visible)]="this.staticDialogService.displayOverrideDialog"
    appendTo="body"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '40vw' }"
    header="Override Credit Limit"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="flex flex-wrap border-top-1 surface-border pt-4"></div>
    <app-override-limit></app-override-limit>

    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-3"></div>
    </ng-template>
  </p-dialog>
</div>
