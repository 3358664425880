<div class="grid" style="width: 100%">
  <div class="col-12">
    <div class="surface-card p-4 shadow-2 border-round">
      <div class="flex">
        <div
          class="w-5 py-8 text-center flex align-items-center justify-content-center"
        >
          <button
            pButton
            pRipple
            label="Check Credit"
            routerLink="creditcheck"
            icon=""
            class="p-button-rounded p-button-outlined p-button-lg"
          ></button>
        </div>
        <div class="w-2">
          <div
            class="flex relative align-items-center justify-content-center mx-3 py-3 min-h-full"
          >
            <div
              class="border-left-1 border-300 top-0 left-50 absolute h-full"
            ></div>
            <div class="py-2 z-1 surface-0">
              <span class="text-900 font-medium">OR</span>
            </div>
          </div>
        </div>
        <div class="w-5 py-8 flex align-items-center justify-content-center">
          <button
            pButton
            pRipple
            label="Transact"
            routerLink="till"
            icon=""
            class="p-button-rounded p-button-success p-button-lg"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
