import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  customerHasCreditAndNoOverdueInvoices(customerName: string) {
    this.messageService.add({
      key: 'mainApplication',
      severity: 'success',
      summary: 'Customer Has Good Credit',
      detail: `${customerName} has credit available and no overdue invoices`,
    });
  }

  customerDoesNotHaveCreditOrHasOverdueInvoices(customerName: string) {
    this.messageService.add({
      key: 'mainApplication',
      severity: 'error',
      summary: 'Customer Does Not Have Good Credit',
      detail: `${customerName} does not have credit available or has overdue invoices`,
    });
  }

  saleCompleted() {
    this.messageService.add({
      key: 'mainApplication',
      severity: 'success',
      summary: 'Sale Complete',
      detail: 'The sale has been completed',
    });
  }

  orderNotAffordable() {
    this.messageService.add({
      key: 'mainApplicationErrors',
      severity: 'warn',
      summary: 'Credit Limit Reached',
      detail: 'The intended sale total exceeds the credit limit',
    });
  }

  userAdded() {
    this.messageService.add({
      key: 'mainApplication',
      severity: 'success',
      summary: 'New User Added',
      detail: 'A new user has been added to the system',
    });
  }

  transactionOverrideLimitPassed() {
    this.messageService.add({
      key: 'mainApplication',
      severity: 'success',
      summary: 'Credit Limit Override Successful',
      detail: `Authorisation succeeded. The sale can now be completed.`,
    });
  }

  transactionOverrideLimitFailed() {
    this.messageService.add({
      key: 'mainApplicationErrors',
      severity: 'error',
      summary: 'Credit Limit Override Failed',
      detail: `Authorisation failed. Incorrect PIN. Please try again or cancel sale.`,
    });
  }

  clearErrors() {
    this.messageService.clear('mainApplicationErrors');
  }
}
