import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import {
  catchError,
  concat,
  EMPTY,
  map,
  Observable,
  of,
  retry,
  share,
  throwError,
  timeout,
  TimeoutError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { OnlineStatusService } from './online-status.service';
import { InvoiceService } from './entities/invoice.service';
import { Product } from '../_models/product';

const OFFLINE_QUEUE_STORAGE_KEY = 'offlineQueue';
const HTTP_TIMEOUT_IN_MS = 5000;

@Injectable({
  providedIn: 'root',
})
export class OfflineQueueService {
  offlineQueueLists: any = { invoices: [], invoiceProductLines: [] };

  invoiceRequests: any = [];
  invoiceProductLineRequests: any = [];
  invoiceReferences: any = {};
  invoiceResponses: any = [];

  constructor(
    private http: HttpClient,
    private onlineStatusService: OnlineStatusService
  ) {
    this.clearQueue();
    this.onlineStatusService.networkStatusObservable$.subscribe((status) => {
      // console.log('status from offline queue service', status);
      if (status) {
        this.processOfflineQueue();
      } else {
        this.clearQueue();
      }
    });
  }

  tryPostAPI<Type>(endpoint: string, data: any): Observable<any> {
    const params = {
      observe: 'response',
      responseType: 'json',
    };
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        timeout(HTTP_TIMEOUT_IN_MS),
        retry(3),
        catchError((err: HttpErrorResponse) =>
          this.handleError(err, endpoint, data, params)
        ),
        share()
      );
  }

  handleError<T>(
    error: any,
    url: any,
    data: any,
    params: any
  ): Observable<any> {
    if (this.offlineOrBadConnection(error)) {
      return EMPTY;
    } else {
      console.log('A backend error occurred.', error);

      if (url.includes('invoice/add')) {
        // invoices
        // --------

        this.loadQueue(); // gets the offline queue from local storage
        this.offlineQueueLists.invoices.push(data); // adds the invoice to the offline queue

        // todo: implement this
        let invoiceLines =
          this.convertActiveInvoiceItemstoInvoiceLinesUsingUUID(
            data.items,
            data.invoice_reference,
            data.notes
          );

        this.offlineQueueLists.invoiceProductLines.push(...invoiceLines); // adds the invoice to the offline queue

        this.saveQueue(); // saves the offline queue to local storage
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      return throwError(error);
    }
  }

  offlineOrBadConnection(err: HttpErrorResponse): boolean {
    // todo: implement properly
    // https://daanstolp.nl/articles/2021/angular-pwa-2/#turn-your-app-into-a-pwa--the-power-of-service-workers
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      this.onlineStatusService.networkStatus
    );
  }

  responseIsSuccess(response: HttpResponse<any>): boolean {
    if (response.status === 200 || response.status === 201) {
      return true;
    } else return false;
  }

  // ========================================

  saveQueue() {
    // console.log('saving queue to local storage');
    localStorage.setItem(
      OFFLINE_QUEUE_STORAGE_KEY,
      JSON.stringify(this.offlineQueueLists)
    );
  }

  loadQueue() {
    if (localStorage.getItem(OFFLINE_QUEUE_STORAGE_KEY)) {
      this.offlineQueueLists = JSON.parse(
        localStorage.getItem(OFFLINE_QUEUE_STORAGE_KEY) as string
      );
    } else {
      localStorage.setItem(
        OFFLINE_QUEUE_STORAGE_KEY,
        JSON.stringify(this.offlineQueueLists)
      );
    }
  }

  clearQueueLists() {
    this.offlineQueueLists = { invoices: [], invoiceProductLines: [] };
  }

  clearQueue() {
    this.clearQueueLists();
    localStorage.setItem(
      OFFLINE_QUEUE_STORAGE_KEY,
      JSON.stringify(this.offlineQueueLists)
    );
  }

  clearRequestsandResponses() {
    this.invoiceReferences = {};
    this.invoiceResponses = [];
    this.invoiceRequests = [];
    this.invoiceProductLineRequests = [];
  }

  processOfflineQueue() {
    this.clearQueueLists(); // clears the variable
    this.offlineQueueLists = JSON.parse(
      localStorage.getItem(OFFLINE_QUEUE_STORAGE_KEY) as string
    ); // loads the offline queue from local storage
    this.clearRequestsandResponses(); // clears the requests and responses

    this.stepThroughInvoices();
    const invoiceQueueRequests$ = concat(...this.invoiceRequests);

    invoiceQueueRequests$.subscribe(
      (response: any) => {
        if (typeof response === 'object') {
          if (response.url.includes('invoice/add')) {
            let returnedInvoice = response.body;
            if (
              !this.invoiceReferences.hasOwnProperty([
                returnedInvoice.invoice_reference,
              ])
            ) {
              this.invoiceReferences = {
                ...this.invoiceReferences,
                [returnedInvoice.invoice_reference]: returnedInvoice.id,
              };
            }
            // remove the invoice from the offline queue
            this.offlineQueueLists.invoices =
              this.offlineQueueLists.invoices.filter(
                (item: any) =>
                  item.invoice_reference !== returnedInvoice.invoice_reference
              );
            this.saveQueue();
          }
        }
      },
      () => {},
      () => {
        this.stepThroughInvoiceProductLines();
        const invoiceProductLineQueueRequests$ = concat(
          ...this.invoiceProductLineRequests
        );
        console.log('invoices processed and removed from queue');
        invoiceProductLineQueueRequests$.subscribe((response: any) => {
          if (typeof response === 'object') {
            if (response.url.includes('invoiceproductline/add')) {
              let returnedInvoiceProductLine = response.body;

              let invoiceProductLineOrig = {
                ...returnedInvoiceProductLine,
                invoice_id: 0,
                invoice_reference: this.getKeyByValue(
                  this.invoiceReferences,
                  returnedInvoiceProductLine.invoice_id
                ),
              };

              console.log(invoiceProductLineOrig);

              // let invoiceLineTemp = returnedInvoiceProductLine;
              // let invoiceProductLinesTemp =
              //   this.offlineQueueLists.invoiceProductLines;

              // remove the invoice product line from the offline queue

              console.log(
                JSON.stringify(this.offlineQueueLists.invoiceProductLines[0])
              );

              // console.log(JSON.stringify(invoiceProductLineOrig));
              console.log(
                JSON.stringify({
                  quantity: invoiceProductLineOrig.quantity,
                  comments: invoiceProductLineOrig.comments,
                  invoice_id: invoiceProductLineOrig.invoice_id,
                  invoice_reference: invoiceProductLineOrig.invoice_reference,
                  product_id: invoiceProductLineOrig.product_id,
                  unit_price_exclusive:
                    invoiceProductLineOrig.unit_price_exclusive,
                  unit_price_inclusive:
                    invoiceProductLineOrig.unit_price_inclusive,
                })
              );

              this.offlineQueueLists.invoiceProductLines =
                this.offlineQueueLists.invoiceProductLines.filter(
                  (item: any) =>
                    item.invoice_reference !==
                      invoiceProductLineOrig.invoice_reference &&
                    item.product_id !== invoiceProductLineOrig.product_id &&
                    item.unit_price_exclusive !==
                      invoiceProductLineOrig.unit_price_exclusive &&
                    item.unit_price_inclusive !==
                      invoiceProductLineOrig.unit_price_inclusive &&
                    item.quantity !== invoiceProductLineOrig.quantity
                );

              console.log(
                ' line lists:',
                this.offlineQueueLists.invoiceProductLines
              );

              this.saveQueue();
            }
          }
        });
      }
    );
  }

  convertActiveInvoiceItemstoInvoiceLinesUsingUUID(
    invoiceItems: Product[],
    invoiceReference: string,
    invoiceNotes: string
  ) {
    console.log('invoiceItems', invoiceItems);
    console.log('invoice_reference', invoiceReference);

    let new_invoice_lines: any[] = [];
    let new_invoice_product_line = {};

    invoiceItems.forEach((item: any) => {
      let comment = '';
      if (item.description.toLowerCase().includes('m diesel-')) {
        comment = invoiceNotes;
      }

      new_invoice_product_line = {
        quantity: item.product_quantity,
        comments: comment,
        // make invoice_id 0 for now
        invoice_id: 0,
        invoice_reference: invoiceReference,
        product_id: item.id,
        unit_price_inclusive: item.price_inclusive,
        unit_price_exclusive: item.price_exclusive,
      };

      new_invoice_lines.push(new_invoice_product_line);
    });
    return new_invoice_lines;
  }

  stepThroughInvoices() {
    if (this.offlineQueueLists.invoices.length > 0) {
      this.offlineQueueLists.invoices.forEach((invoice: any) => {
        const obs$ = this.tryPostAPI('invoice/add', invoice).pipe(
          map((response) => response)
        );
        console.log(invoice);
        this.invoiceRequests.push(obs$);
      });
    }
    return true;
  }

  stepThroughInvoiceProductLines() {
    if (this.offlineQueueLists.invoiceProductLines.length > 0) {
      this.offlineQueueLists.invoiceProductLines.forEach(
        (invoiceProductLine: any) => {
          // todo: assign the invoice id to the invoice product line

          let invoiceProductLineTemp = (({ invoice_reference, ...o }) => o)(
            invoiceProductLine
          );

          invoiceProductLineTemp.invoice_id =
            this.invoiceReferences[invoiceProductLine.invoice_reference];

          const obsInvoiceProductLine$ = this.tryPostAPI(
            'invoiceproductline/add',
            invoiceProductLineTemp
          ).pipe(
            map((responseInvoiceProductLine) => responseInvoiceProductLine)
          );
          console.log(invoiceProductLine);
          this.invoiceProductLineRequests.push(obsInvoiceProductLine$);
        }
      );
    }
    return true;
  }

  getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }
}
