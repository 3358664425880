<!-- add the fields to the notes  -->
<!-- 
note fields:
============

order_no?: string;
truck_reg?: string;
driver_name?: string;
mileage?: number;
fleet_ref?: string; 
-->

<!-- todo: add text if necessary -->
<!-- <p>These fields are mandatory</p> -->
<form [formGroup]="this.invoiceService.notesForm" (ngSubmit)="submitNotes()">
  <div class="grid formgrid p-fluid">
    <!-- order no -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="order_no" class="font-medium text-900">Order No:</label>
      <input
        id="order_no"
        type="text"
        [autofocus]="true"
        pInputText
        [formControl]="
          convertToFormControl(this.invoiceService.notesForm.get('order_no'))
        "
      />
    </div>
    <!-- truck registration -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="truck_reg" class="font-medium text-900"
        >Truck Registration</label
      >
      <input
        id="truck_reg"
        type="text"
        pInputText
        [formControl]="
          convertToFormControl(this.invoiceService.notesForm.get('truck_reg'))
        "
      />
    </div>
    <!-- driver name -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="driver_name" class="font-medium text-900">Driver Name</label>
      <input
        id="driver_name"
        type="text"
        pInputText
        [formControl]="
          convertToFormControl(this.invoiceService.notesForm.get('driver_name'))
        "
      />
    </div>
    <!-- fleet reference -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="fleet_ref" class="font-medium text-900"
        >Fleet Reference</label
      >
      <input
        id="fleet_ref"
        type="text"
        pInputText
        [formControl]="
          convertToFormControl(this.invoiceService.notesForm.get('fleet_ref'))
        "
      />
    </div>
    <!-- mileage -->
    <div class="field mb-4 col-12 md:col-6">
      <label for="mileage" class="font-medium text-900"
        >Mileage (Odometer reading)</label
      >
      <p-inputNumber
        id="mileage"
        [showButtons]="true"
        [formControl]="
          convertToFormControl(this.invoiceService.notesForm.get('mileage'))
        "
      ></p-inputNumber>
    </div>

    <div class="mb-4 col-12 md:col-6">
      <button
        pButton
        pRipple
        icon="pi pi-check"
        label="Recieve Payment"
        class="p-button-success mb-3 w-full"
        type="submit"
        on="submitNotes()"
      ></button>

      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-check"
        label="Cancel Transaction"
        class="p-button-warning mb-3 w-full"
        (click)="this.staticDialogService.closeTransactionCompleteDialog()"
      ></button>
    </div>
  </div>
</form>
