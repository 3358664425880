import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  private selectedRowsSubject = new BehaviorSubject<any[]>([]);
  selectedRows$: Observable<any[]> = this.selectedRowsSubject.asObservable();
  selected: boolean = false;

  setSelectedRows(selectedRows: any[]): void {
    this.selectedRowsSubject.next(selectedRows);
  }
  
  resetSelectedRows(unselectedRow: any): void {
    const currentSelectedRows = this.selectedRowsSubject.value;
    const filteredRows = currentSelectedRows.filter((row) => row !== unselectedRow);
    this.selectedRowsSubject.next(filteredRows);
}

  instantiateSelectedRows(): void {
    this.selectedRowsSubject = new BehaviorSubject<any[]>([]);
  }

  getSelectedRows(): Observable<any[]> {
    return this.selectedRowsSubject.asObservable();
  }

}