<p>
  The current 4 digit authorisation pin is (this pin resets every 24 hours):
</p>
<p class="text-xl text-blue-900">
  <strong>{{ paymentAuthorisationService.authorisationPinValue }}</strong>
</p>
<button
  pButton
  pRipple
  label="Generate New"
  (click)="paymentAuthorisationService.generateNewAuthorisationPin()"
></button>
