import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Pricelist } from '../../_models/pricelist';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class PricelistService {
  // properties
  // ----------
  @Output() pricelists: any;
  @Output() pricelistObservable$: Subject<any> = new Subject<any>();

  pricelistQuery$: any;
  constructor(public api: ApiService) {}

  getPricelists() {
    this.pricelistQuery$ = this.api
      .getAPI<Pricelist>('pricelist')
      .subscribe((response) => {
        this.pricelists = response.body;
        this.pricelistObservable$.next(this.pricelists);
      });
  }
}
