import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ValidationService } from 'src/app/_services/validation.service';

@Component({
  selector: 'app-creditcheck',
  templateUrl: './credit-check.component.html',
  styleUrls: ['./credit-check.component.scss'],
})
export class CreditCheckComponent implements OnInit {
  constructor(
    public customerService: CustomerService,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  checkCredit() {
    if (this.customerService.activeCustomer) {
      if (
        this.validationService.doesActiveCustomerHaveCredit() &&
        !this.validationService.doesActiveCustomerHaveOverdueInvoices()
      ) {
        this.notificationService.customerHasCreditAndNoOverdueInvoices(
          this.customerService.activeCustomer.name
        );
      } else {
        this.notificationService.customerDoesNotHaveCreditOrHasOverdueInvoices(
          this.customerService.activeCustomer.name
        );
      }
      this.router.navigate(['/']);
    }
  }
}
