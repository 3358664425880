import { Component, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { UserService } from 'src/app/_services';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';
import { ProductService } from 'src/app/_services/entities/product.service';

@Component({
  selector: 'app-sales-view',
  templateUrl: './sales-view.component.html',
  styleUrls: ['./sales-view.component.scss'],
})
export class SalesViewComponent implements OnInit {
  @Output() combined$: any;

  @Output() combinedObservable$: Subject<any> = new Subject<any>();
  combined: any;
  combinedLoading: boolean;
  constructor(
    public invoiceService: InvoiceService,
    public customerService: CustomerService,

    public userService: UserService,
    public productService: ProductService
  ) {
    this.combinedLoading = false;
  }

  ngOnInit(): void {
    this.combinedLoading = true;
    this.combined$ = combineLatest([
      this.invoiceService.invoiceObservable$,
      this.customerService.customersObservable$,
      // this.invoiceService.invoiceProductLinesObservable$,
    ]).subscribe(([invoices, customers]) => {
      invoices.map((invoice: any) => {
        invoice.customer = customers.filter((customer: any) => {
          return customer.id === invoice.customer_id;
        })[0];
        this.combined = invoices;
        this.combinedObservable$.next(this.combined);
        this.combinedLoading = false;
      });

      return this.combined.sort((a: any, b: any) => {
        let textA = String(a.sage_invoice_reference).toLowerCase().trim();
        let textB = String(b.sage_invoice_reference).toLowerCase().trim();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    });
  }

  getInputValue(e: any): any {
    const inputelement = e.target as HTMLInputElement;
    return inputelement.value;
  }
}
