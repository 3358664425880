import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Product } from 'src/app/_models/product';
import { Role } from 'src/app/_models/role';
import { User } from 'src/app/_models/user';
import { AuthenticationService, UserService } from 'src/app/_services';
import { ApiService } from 'src/app/_services/api.service';
import { CustomerService } from 'src/app/_services/entities/customer.service';
import { DataSyncService } from 'src/app/_services/data-sync.service';
import { StaticDialogService } from 'src/app/_services/dialogs/static-dialog.service';
import { InvoiceService } from 'src/app/_services/entities/invoice.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  constructor(
    public userService: UserService,
    public staticDialogService: StaticDialogService,
    public dataSyncService: DataSyncService
  ) {}

  ngOnInit(): void {}
}
