<div
  class="block overflow-hidden surface-overlay p-4 md:w-full"
  style="max-width: 90vw"
>
  <div class="card">
    <p-table
      scrollHeight="300px"
      #dt1
      [value]="dispenses"
      dataKey="id"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="[
        'attendant_name',
        'attendant_reference',
        'attendant_tag_number',
        'dispense_litres',
        'nozzle_ref',
        'pump_ref',
        'transaction_begin',
        'transaction_end',
        'status'
      ]"
      selectionMode="multiple"
      [(selection)]="selectedDispenses"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      [metaKeySelection]="true"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="clear(dt1)"
          ></button>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              #inputField
              type="text"
              (input)="dt1.filterGlobal(inputField.value, 'contains')"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!-- <th>ID</th> -->
          <th>Name</th>
          <!-- <th>Reference</th> -->
          <th>Attendant Tag</th>
          <th>Liters</th>
          <th>Pump</th>
          <th>Nozzle</th>
          <th>Transaction Begin</th>
          <th>Transaction End</th>
          <th>Status</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-item
        let-rowIndex="rowIndex"
        [pSelectable]="item"
      >
        <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
          <!-- <td>
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td> -->
          <!-- <td>{{ item.attendant_id }}</td> -->
          <td>{{ item.attendant_name }}</td>
          <!-- <td>{{ item.attendant_reference }}</td> -->
          <td>{{ item.attendant_tag_number }}</td>
          <td>{{ item.dispense_litres }}</td>
          <td>{{ item.pump_ref }}</td>
          <td>{{ item.nozzle_ref }}</td>
          <td>{{ item.transaction_begin | date : "yyyy-MM-dd hh:mm" }}</td>
          <td>{{ item.transaction_end | date : "yyyy-MM-dd hh:mm" }}</td>
          <td>
            <p-tag
              [severity]="item.status === 'DISPENSED' ? 'warning' : 'success'"
              [value]="item.status"
            ></p-tag>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No dispenses found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="isCertainPage()" class="p-4">
      <button
        pButton
        pRipple
        label="Add"
        class=""
        (click)="addDispItems(selectedDispenses); setRowSelected()"
      ></button>
    </div>
  </div>
</div>
