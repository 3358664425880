<div class="grid formgrid p-fluid">
  <!-- <div class="field mt-4 col-12 md:col-4">Receipt Printer IP Address:</div>
  <div class="field mt-4 col-3 md:col-2">
    <p-inputNumber [min]="0" [max]="255" [maxlength]="3"></p-inputNumber>
  </div>
  <div class="field mt-4 col-3 md:col-2">
    <p-inputNumber [min]="0" [max]="255" [maxlength]="3"></p-inputNumber>
  </div>
  <div class="field mt-4 col-3 md:col-2">
    <p-inputNumber [min]="0" [max]="255" [maxlength]="3"></p-inputNumber>
  </div>
  <div class="field mt-4 col-3 md:col-2">
    <p-inputNumber [min]="0" [max]="255" [maxlength]="3"></p-inputNumber>
  </div> -->
  <!-- <div class="field col-6 md:col-6">
    <label for="printer_ip">IP Address</label>
    <input
      pInputText
      type="text"
      id="printer_ip"
      [(ngModel)]="printingSettings.receiptPrinterSettings.ipAddress"
    />
  </div>

  <div class="field col-6 md:col-6">
    <label for="printer_timeout" class="font-medium text-900">Timeout</label>
    <br />
    <p-inputNumber
      id="printer_timeout"
      [disabled]="true"
      [(ngModel)]="printingSettings.receiptPrinterSettings.timeout"
      (ngModelChange)="updateTimeout($event)"
      [min]="1000"
      [max]="60000"
      suffix=" ms"
      (onFocus)="printingSettings.receiptPrinterSettings.timeout = null"
    ></p-inputNumber>
  </div>
  <div class="field col-6 md:col-6">
    <p-checkbox
      label="Use HTTPS"
      [(ngModel)]="printingSettings.receiptPrinterSettings.useHTTPS"
      [binary]="true"
      [disabled]="true"
    ></p-checkbox>
  </div>

  <div class="field col-6 md:col-6">
    <button (click)="printingSettings.saveSettings()" pButton>
      Save Printer Settings
    </button>
  </div>
</div> -->

  <div>
    <!-- <input
      [(ngModel)]="ip"
      type="text"
      name="ip"
      placeholder="IP of printer with WebPRNT"
    />
    <button pButton (click)="receiptPrintingService.connectToWebPrint()">
      Connect to WebPRNT
    </button> -->

    <button pButton (click)="receiptPrintingService.requestUsb()">
      Connect to USB
    </button>
    &nbsp;

    <button
      pButton
      class="button-primary"
      (click)="receiptPrintingService.print()"
      [disabled]="receiptPrintingService.status === false"
    >
      Print!
    </button>
  </div>
</div>
