<div class="grid formgrid p-fluid">
  <p>To override the limit, you need a 4 digit authorisation key</p>
  <div class="field col-6 md:col-6">
    <label for="userEnteredAuthorisationPin">Authorisation PIN</label>
    <input
      pInputText
      [autofocus]="true"
      type="text"
      maxlength="4"
      minlength="4"
      id="userEnteredAuthorisationPin"
      [(ngModel)]="paymentAuthorisationService.userEnteredAuthorisationPin"
      (keydown)="onKeyDownFunction($event)"
    />
  </div>
</div>
<div class="">
  <p-button
    label="Submit"
    class="p-button-raised"
    (onClick)="authoriseOverrideLimit()"
  ></p-button>
</div>
