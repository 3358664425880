import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NONE_TYPE } from '@angular/compiler';
import { Injectable } from '@angular/core';
import {
  catchError,
  debounceTime,
  Observable,
  repeat,
  shareReplay,
  throwError,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAPI<Type>(
    endpoint: string,
    queryParams?: any,
    pollInterval?: number
  ): Observable<any> {
    let parameters = {};
    if (pollInterval) {
      if (queryParams) {
        parameters = queryParams;
        console.log('parameters', parameters);
      }
      // fixme: this is only temporary, need to find a better way to do this
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: 'response',
          responseType: 'json',
          params: parameters,
        })
        .pipe(
          catchError(this.handleError),
          shareReplay(),
          repeat({ delay: pollInterval, count: 1 })
        );
    } else {
      if (queryParams) {
        parameters = queryParams;
        console.log('parameters', parameters);
      }
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: 'response',
          responseType: 'json',
          params: parameters,
        })
        .pipe(catchError(this.handleError), shareReplay(1));
    }
  }

  postAPI<Type>(endpoint: string, data: any): Observable<any> {
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(debounceTime(5000), catchError(this.handleError));
  }

  updateAPI<Type>(endpoint: string, id: number, data: any): Observable<any> {
    return this.http
      .put<Type>(`${environment.apiUrl}/${endpoint}/${id}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (error.status === 401) {
      // window.alert(errorMessage);
      console.log(errorMessage);
    } else {
      window.alert(errorMessage);
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
